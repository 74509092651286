/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from "react";
import ReactSVG from "react-svg";

// const MaterialIcon = ({ icon, stroke, fill, ...props }) => {
//   const iconName = icon.replace(/Icon$/, '');
//   const resolved = require(`@material-ui/icons/${iconName}`).default;

//   if (!resolved) {
//     throw Error(`Could not find @material-ui/icons/${iconName}`);
//   }

//   const iconComponent = React.createElement(resolved, {
//     style: { stroke, fill }
//   });

//   return (
//     <span className="icon" {...props}>
//       {iconComponent}
//     </span>
//   );
// };

export default ({ glyph, stroke, fill, size, line, ...props }) => {
  // if (glyph.indexOf('mui-') !== -1) {
  //   return (
  //     <MaterialIcon
  //       icon={glyph.replace('mui-', '')}
  //       stroke={stroke}
  //       fill={fill}
  //       {...props}
  //     />
  //   );
  // }

  const widthHeight = size || 24;
  const strokeLine = line || 2;

  return (
    <ReactSVG
      src={`/icons/${glyph}.svg`}
      beforeInjection={svg => {
        if (stroke) svg.setAttribute("stroke", stroke);
        if (fill) svg.setAttribute("fill", fill);
        svg.setAttribute("stroke-width", strokeLine);
        svg.setAttribute(
          "style",
          `width: ${widthHeight}px;height: ${widthHeight}px`
        );
      }}
      wrapper="span"
      className="icon"
      {...props}
    />
  );
};
