import React from "react";
import { Chip, Card, CardActions, CardContent } from "@material-ui/core";
import { H5, P, LinkButton, Icon, Colours } from "../../components/ui";

export default ({ id, icon, title, tags, description, path, link }) => (
  <Card>
    <CardContent
      style={{
        height: "200px",
        lineHeight: "200px",
        textAlign: "center",
        backgroundColor: Colours.sheetly
      }}
    >
      <Icon size="96" stroke="white" glyph={icon} />
    </CardContent>
    <CardContent style={{ height: 200 }}>
      <H5>{title}</H5>
      <div style={{ marginBottom: "1em" }}>
        {tags.map(tag => (
          <Chip label={tag.name} style={{ marginRight: "0.5em" }} />
        ))}
      </div>
      <P>{description}</P>
    </CardContent>
    <CardActions style={{ display: "flex", justifyContent: "space-between" }}>
      <LinkButton size="small" key={id} to={`/${path}`} ui="secondary">
        Launch
      </LinkButton>
      <LinkButton size="small" key={id} to={link} ui="secondary">
        Learn More
      </LinkButton>
    </CardActions>
  </Card>
);
