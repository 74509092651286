import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { StitchProvider, StitchContext } from "stitch";
import theme from "./components/app/theme";
import { I18nProvider, GuestRouter, AuthenticatedRouter } from "./lib";
import { Auth } from "lib";

const App = () => {
  const loggedIn = Auth.isAuthenticated();

  return (
    <MuiThemeProvider theme={theme}>
      <I18nProvider locale="en">
        {/* <StitchProvider>
        <StitchContext.Consumer>
          {({ loggedIn }) => ( */}
        <>
          {loggedIn && <AuthenticatedRouter />}
          {!loggedIn && <GuestRouter />}
        </>
        {/*)}
        </StitchContext.Consumer>
           </StitchProvider> */}
      </I18nProvider>
    </MuiThemeProvider>
  );
};

export default App;
