import React, { useEffect, useState, useContext, useRef } from "react";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { LicenseManager } from "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import humanize from "humanize-string";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import {
  Paper,
  Tabs,
  Tab,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { Button, Modal } from "components/ui";
import { AuthenticatedLayout, InspectorBar } from "components/app";
import { HtmlEditor, Toolbar } from "components/app/grid";
// Data
import sheetJSON from "dummyData/sheets.json";
import entityJSON from "dummyData/entity.json";
import attributeJSON from "dummyData/attribute.json";
import dataJSON from "dummyData/data2.json";
import viewJSON from "dummyData/view.json";
import channelJSON from "dummyData/channel.json";
import lookupJSON from "dummyData/lookup.json";
import setJSON from "dummyData/set.json";
import filterJSON from "dummyData/filter.json";
// Services
import { SheetDataService, WebSocketContext, useQuery } from "lib";
import {
  CHANNELS,
  FILTER_FROM_FILTER_TYPE,
  DISPLAYS,
  RENDERER_FROM_DISPLAY,
  EDITOR_FROM_TYPECAST,
  TOOLBAR_FILTER_TYPE,
  FILTER_OPTIONS,
  SELECTION_OPTIONS,
  TYPECASTS,
  VIEW_OPTIONS
} from "lib/constants";
// Components
import {
  CurrencyRenderer,
  BooleanRenderer,
  ImageRenderer,
  TextRenderer,
  HtmlRenderer,
  DateEditor,
  CalculatorEditor,
  OverallHealthRenderer,
  CustomToolPanel,
  Widget
} from "components/app/grid";

LicenseManager.setLicenseKey(
  "Evaluation_License_Not_For_Production_30_December_2019__MTU3NzY2NDAwMDAwMA==82f726e0f9e347787984f7c5e16cebcc"
);

let gridApi;
let gridColumnApi;
let _rows = [];
let _cols = [];
let potentialParent = null;

const getSelected = () => {
  const selectedNodes = gridApi.getSelectedNodes();
  const selectedData = selectedNodes.map(node => node.data);
  const selectedDataStringPresentation = selectedData
    .map(node => `${node.id} ${node.name}`)
    .join(", ");
  // eslint-disable-next-line no-console
  console.log(`Selected nodes: ${selectedDataStringPresentation}`);
};

/* const statusBar = {
  statusPanels: [
    {
      statusPanel: 'agTotalRowCountComponent',
      align: 'left'
    },
    { statusPanel: 'agFilteredRowCountComponent' },
    { statusPanel: 'agSelectedRowCountComponent' },
    { statusPanel: 'agAggregationComponent' }
  ]
};
 */

const generateBlankRows = (counter, sheetData, seq, channel) => {
  const blank = {
    sheet_entity_row_id: null,
    sheet_id: sheetData.id,
    organization_id: "28a83b47-a6b3-4634-8125-f0afa723427c", // get from current user metadata
    entity_row_id: null,
    channels: null
  };
  const blankRows = [];

  for (let i = 0; i < counter; i++) {
    const generatedId = uuidv4();
    blankRows.push({
      ...blank,
      id: generatedId,
      seq: seq + (i + 1),
      sheet_entity_row_id: generatedId
    });
  }

  return blankRows;
};

function Products({ intl }) {
  //const [rows, setRows] = useState([]);
  const [inspector, setInspector] = useState(false);
  const [picker, setPicker] = useState("assets2product");
  const [widget, setWidget] = useState(null);
  const [view, setView] = useState({});
  //const [channel, setChannel] = useState(0);
  const [selected, setSelected] = useState(null);

  // My code starts from here
  const [gridDestroyed, setGridDestroyed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  //const [rows, setRows] = useState([]);
  //const [cols, setCols] = useState([]);
  const [channelData, setChannelData] = useState([]);
  const [sheetData, setSheetData] = useState({});
  const [sets, setSets] = useState([]);
  const [entities, setEntities] = useState({});
  const [views, setViews] = useState([]);
  const [htmlEditorData, setHtmlEditorData] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsToDisplay, setRowsToDisplay] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [savedFilters, setSavedFilters] = useState([]);
  const [activeRowNode, setActiveRowNode] = useState({});

  const WSContext = useContext(WebSocketContext); // Use when back-end is finished to get data

  const history = useHistory();

  const query = useQuery();
  const urlParams = {
    sheet: query.get("sheet"),
    channel: query.get("channel") || CHANNELS.core,
    view: query.get("view"),
    alternative: query.get("alternative")
  };

  const prevView = useRef();

  useEffect(() => {
    prevView.current = view;
  });

  const prevViewObj = prevView.current || {};
  const hierarchyColumn =
    _cols.find(col => col.display === DISPLAYS.hierarchy) || "";

  useEffect(() => {
    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!gridApi || !gridColumnApi) return;

    // const hierarchyColumn =
    //   _cols.find((col) => col.display === DISPLAYS.hierarchy) || {};
    // Set visible columns through view
    gridColumnApi.setColumnsVisible(view.attribute_codes || [], true);
    // Hide SKU column and show Hierarchy column if tree view is enabled
    if (urlParams.alternative === DISPLAYS.hierarchy) {
      gridColumnApi.setColumnVisible("sku", false);
      //gridColumnApi.setColumnVisible(hierarchyColumn.code, true);
    } else {
      //gridColumnApi.setColumnVisible(hierarchyColumn.code, false);
    }

    if (view.code === "edit_view" || view === "edit_view") {
      gridApi.openToolPanel("columns");
    } else {
      gridApi.closeToolPanel();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, urlParams.alternative, gridApi, gridColumnApi]);

  useEffect(() => {
    if (view.alternative || (prevViewObj.alternative && !view.alternative)) {
      setGridDestroyed(true);
      setTimeout(() => {
        setGridDestroyed(false);
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const initData = async () => {
    // Get Sheet object ( maybe will be need in the future )
    const sheetObj = SheetDataService.getSheet(sheetJSON, urlParams.sheet);
    setSheetData(sheetObj);

    // Get View object
    const viewObj = SheetDataService.getView(viewJSON, urlParams.view);
    setView(
      urlParams.alternative
        ? { ...viewObj, alternative: urlParams.alternative }
        : viewObj
    );

    // Cols data based on view param
    //const colsData = SheetDataService.getColsData(attributeJSON);
    _cols = attributeJSON;
    //setCols(colsData);

    // Get full Rows data based on channel
    const rowsData = SheetDataService.getRowsData(dataJSON, urlParams.channel);
    _rows = rowsData;
    //setRows(rowsData);

    // Channel data based on channel url param
    const channelData = SheetDataService.getChannelData(
      channelJSON,
      urlParams.channel
    );
    setChannelData(channelData);

    // Get sets and lookups
    const sets = SheetDataService.getDropdownOptions(
      attributeJSON,
      lookupJSON,
      setJSON,
      urlParams.channel
    );
    setSets(sets);

    // Get entities
    const entity = entityJSON.length > 0 ? entityJSON[0] : {};
    setEntities(entity);

    // Get views
    setViews(viewJSON);

    // Get saved filters
    setSavedFilters(filterJSON);
  };

  const renderModalContent = (type, params = {}) =>
    ({
      htmlEditor: (
        <div>
          <HtmlEditor
            initValue={htmlEditorData.value}
            onChange={value => {
              htmlEditorData.node.setDataValue(
                htmlEditorData.column.colId,
                value
              );
            }}
          />
        </div>
      ),
      deleteConfirm: (
        <div>
          <DialogTitle id="alert-dialog-title">
            {intl.formatMessage(
              {
                id: "products.deleteConfirmation",
                defaultMessage: "Are you sure you want to delete row/s?"
              },
              { number: params.number }
            )}
          </DialogTitle>
          <DialogContent></DialogContent>
          <DialogActions>
            <Button
              ui="primary"
              onClick={() => {
                deleteRows(params.rangeSelection);
                setIsModalOpen(false);
              }}
            >
              Confirm
            </Button>
            <Button
              ui="secondary"
              type="button"
              onClick={() => {
                setIsModalOpen(false);
              }}
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </div>
      )
    }[type]);

  const saveFilters = () => {
    // Update server data through websocket
    //WSContext.addData(appliedFilters);
  };

  const cellClassRules = ({
    api,
    value,
    node,
    data,
    colState,
    sets,
    channel
  }) => {
    const editableCols = SheetDataService.extractEditableCols(data, sets);
    const isOHCol = colState.display === DISPLAYS.overall_health;
    const isEditable = !colState.protect
      ? editableCols.length > 0
        ? editableCols.includes(colState.code)
        : true
      : !colState.protect;
    const isValidType = SheetDataService.testTypecast(
      value,
      colState.typecast,
      sets[colState.code]
    );
    const channelObj =
      (colState.testable &&
        colState.channels &&
        colState.channels.find(c => c.channel_id === channel)) ||
      {};

    let _levelTests = {
      critical: false,
      warning: false,
      readOnly: false,
      good: false
    };
    let _nodes = [];

    api &&
      api.forEachNode(rowNode => {
        rowNode.id !== node.id &&
          rowNode.data &&
          _nodes.push(rowNode.data[colState.code]);
      });

    data.entity_row_id !== null &&
      channelObj.tests &&
      channelObj.tests.forEach(test => {
        const testPassed = SheetDataService.testValue(value, test, _nodes);

        if (!testPassed) {
          _levelTests[test.level] = true;
        }
      });

    if (!isValidType || _levelTests.critical || (isOHCol && value > 999)) {
      _levelTests.critical = true;
    } else if (_levelTests.warning || (isOHCol && value > 0)) {
      _levelTests.warning = true;
    } else if (isOHCol && value === 0) {
      _levelTests.good = true;
    } else if (colState.protect || !isEditable) {
      _levelTests.readOnly = true;
    }

    return _levelTests;
  };

  const cellStyleRules = ({ value, colState }) => {
    const validDate = SheetDataService.validDate(value);
    const boolCleaned = SheetDataService.booleanCleaner(value);
    const isEmpty = SheetDataService.isEmpty(value);

    let styles = {};

    // Align text based on type
    if (
      colState.typecast === TYPECASTS.boolean &&
      (typeof boolCleaned === "boolean" || isEmpty)
    ) {
      styles.textAlign = "center";
    } else if (
      (colState.typecast === TYPECASTS.date ||
        colState.typecast === TYPECASTS.currency ||
        colState.typecast === TYPECASTS.integer ||
        colState.typecast === TYPECASTS.decimal) &&
      (!isNaN(value) || validDate)
    ) {
      styles.textAlign = "right";
    } else {
      styles.textAlign = "left";
    }

    return styles;
  };

  const handleCellKeyPress = params => {
    const { event, value, column, data, node } = params;
    const valueCleaned = SheetDataService.booleanCleaner(value);
    const keyPressed = event.code;
    const colState = _cols.find(col => col.code === column.colId);
    const editableCols = SheetDataService.extractEditableCols(data, sets);
    const isEditable = !colState.protect
      ? editableCols.length > 0
        ? editableCols.includes(column.colId)
        : true
      : !colState.protect;

    if (
      isEditable &&
      keyPressed === "Space" &&
      colState.typecast === TYPECASTS.boolean &&
      (typeof valueCleaned === "boolean" || !value)
    ) {
      node.setDataValue(column.colId, !valueCleaned);
    }
  };

  // const suppressSpace = (params, colState) => {
  //   const { event, api, column, node } = params;
  //   const value = api.getValue(column.colId, node);
  //   const valueCleaned = SheetDataService.booleanCleaner(value);
  //   const keyPressed = event.code;

  //   return typeof valueCleaned === 'boolean' || (colState.typecast === TYPECASTS.boolean && !value)
  //   ? keyPressed === 'Space' : false;
  // };

  const onRowDragMove = event => {
    setPotentialParentForNode(event.api, event.overNode);
  };

  const onRowDragLeave = event => {
    setPotentialParentForNode(event.api, null);
  };

  const handleRowDragEnd = ({ api, node }) => {
    updateLocalRows(api);

    if (urlParams.alternative !== DISPLAYS.hierarchy || !potentialParent) {
      return;
    }
    const movingData = node.data;
    const newParentPath =
      potentialParent.data && potentialParent.data.sys_hierarchy
        ? potentialParent.data.sys_hierarchy
        : [];
    var needToChangeParent = !arePathsEqual(
      newParentPath,
      movingData.sys_hierarchy || []
    );
    var invalidMode = isSelectionParentOfTarget(node, potentialParent);
    if (invalidMode) {
      console.log("invalid move");
    }
    if (needToChangeParent && !invalidMode) {
      var updatedRows = [];
      moveToPath(newParentPath, node, updatedRows);
      gridApi.applyTransaction({ update: updatedRows });
      gridApi.clearFocusedCell();
    }
    setPotentialParentForNode(api, null);

    // Update server data through webSocket
    //WSContext.sendData(params.data);
  };

  const selectionChanged = ({ api }) => {
    const selected = api.getSelectedNodes();
    setSelectedRows(selected);
  };

  const rowSelected = params => {
    // the number of rows selected could be huge, if the user is grouping and selects a group, so
    // to stop the console from clogging up, we only print if in the first 10 (by chance we know
    // the node id's are assigned from 0 upwards)
    if (params.node.id < 10) {
      var valueToPrint = params.node.group
        ? "group (" + params.node.key + ")"
        : params.node.data.name;
      console.log(
        "Callback rowSelected: " +
          valueToPrint +
          " - " +
          params.node.isSelected()
      );
    }
  };

  const handleCellClick = params => {
    const { node, api, column } = params;
    if (column.colId === "rowNumber") {
      api.clearRangeSelection();
      node.setSelected(node && node.isSelected());
    }
  };

  const onCellChange = ({ data, node, column, value }) => {
    //_rows = _rows.map((row) => (row.id === data.id ? data : row));

    // Generate "entity_row_id" if it`s null after cell update
    if (data.entity_row_id === null) {
      const { colId } = column;
      const generatedRowId = uuidv4();
      const newData = { ...data, entity_row_id: generatedRowId };

      _cols.forEach(col => {
        newData[col.code] = newData[col.code] || col.default_value;
      });
      newData[colId] = value;

      node.setData(newData);
      // Update local data of rows
      _rows = _rows.map(row => (row.id === data.id ? newData : row));

      // Update server data through webSocket
      //WSContext.sendData(data);
    }
  };

  const getContextMenuItems = ({ api, defaultItems, node }) => {
    if (node == null) return null;
    const rangeSelection = api.getCellRanges();
    const menuItems = defaultItems.splice(0);
    let numberSelectedRows = 1;

    if (rangeSelection.length === 1) {
      const startRow = rangeSelection[0].startRow;
      const endRow = rangeSelection[0].endRow;
      const fromUpToDown = endRow.rowIndex > startRow.rowIndex;
      numberSelectedRows = fromUpToDown
        ? endRow.rowIndex + 1 - startRow.rowIndex
        : startRow.rowIndex + 1 - endRow.rowIndex;

      menuItems.unshift({
        name:
          numberSelectedRows > 1
            ? `Insert ${numberSelectedRows} rows below`
            : "Insert row below",
        //icon: '',
        action: () =>
          insertRows(
            numberSelectedRows,
            fromUpToDown ? endRow : startRow,
            false
          )
      });
      menuItems.unshift({
        name:
          numberSelectedRows > 1
            ? `Insert ${numberSelectedRows} rows above`
            : "Insert row above",
        //icon: '',
        action: () =>
          insertRows(numberSelectedRows, fromUpToDown ? startRow : endRow, true)
      });
      menuItems.push({
        name:
          numberSelectedRows > 1
            ? `Delete ${numberSelectedRows} rows`
            : "Delete row",
        //icon: '',
        //shortcut: 'Alt + M',
        action: () => {
          setIsModalOpen(true);
          setModalContent({
            type: "deleteConfirm",
            params: { number: numberSelectedRows, rangeSelection },
            isDialog: true
          });
        },
        cssClasses: ["text-danger"]
      });
    }

    return menuItems;
  };

  const insertRows = (insertNumber, selectedRow, above) => {
    if (!selectedRow) return;

    const blankRows = generateBlankRows(
      insertNumber,
      sheetData,
      above ? selectedRow.rowIndex : selectedRow.rowIndex + insertNumber,
      urlParams.channel,
      _cols
    );
    //const newNodes = [];
    const rowIndex = above ? selectedRow.rowIndex : selectedRow.rowIndex + 1;

    // gridApi.forEachNodeAfterFilterAndSort((node) => {
    //   if (node.rowIndex === rowIndex) {
    //     newNodes.push(...blankRows);
    //   }
    //   newNodes.push(node.data);
    // });
    // gridApi.setRowData(newNodes);

    const newRowsData = [..._rows];

    newRowsData.splice(rowIndex, 0, ...blankRows);
    _rows = newRowsData;
    gridApi.setRowData(_rows);
  };

  const deleteRows = selectedRows => {
    const nodesToDelete = [];
    const startRowIndex = selectedRows[0].startRow.rowIndex;
    const endRowIndex = selectedRows[0].endRow.rowIndex;
    const startIndex =
      endRowIndex > startRowIndex ? startRowIndex : endRowIndex;
    const endIndex = endRowIndex > startRowIndex ? endRowIndex : startRowIndex;

    gridApi.forEachNodeAfterFilterAndSort(node => {
      if (node.rowIndex >= startIndex && node.rowIndex <= endIndex) {
        nodesToDelete.push(node.data);
      }
    });

    _rows = _rows.filter(
      row => nodesToDelete.findIndex(node => node.id === row.id) < 0
    );

    gridApi.applyTransaction({ remove: nodesToDelete });
    // gridApi.setRowData(_rows);
    // gridApi.refreshCells();
  };

  const handleFilterChange = ({ api }) => {
    const filters = api.getFilterModel();
    const emptyFilter = Object.keys(filters).length === 0;

    if (emptyFilter) {
      setAppliedFilters(filters);
    } else {
      setAppliedFilters({ label: TOOLBAR_FILTER_TYPE.tempFilter, filters });
      api.deselectAll();
    }

    //api.closeToolPanel();
  };

  const additionalToolbars = () => {
    const filters =
      entities.limiters &&
      entities.limiters.map(limiter => ({
        id: limiter.code,
        labelDefault: limiter.name,
        labelKey: `filter_${limiter.code}`,
        iconKey: limiter.icon,
        toolPanel: "customToolPanel",
        toolPanelParams: {
          code: limiter.code,
          data: entities.limiters
        }
      }));

    return filters || [];
  };

  const onGridReady = ({ api, columnApi }) => {
    gridApi = api;
    gridColumnApi = columnApi;

    // Set visible columns by view
    columnApi.setColumnsVisible(view.attribute_codes || [], true);

    // Set first cell focus
    const firstCol = columnApi.getAllDisplayedColumns()[1];
    api.addCellRange({
      rowStartIndex: 0,
      rowEndIndex: 0,
      columnStart: firstCol,
      columnEnd: firstCol
    });
    api.setFocusedCell(0, firstCol);

    // Open sidebar and toolpanel if edit view mode is enabled
    if (view.code === VIEW_OPTIONS.editView || view === VIEW_OPTIONS.editView) {
      api.setSideBarVisible(true);
      api.openToolPanel("columns");
    }
    api.addGlobalListener((type, event) => {
      // Set number of rows in sheet
      if (
        type === "firstDataRendered" ||
        type === "modelUpdated" ||
        type === "filterChanged"
      ) {
        setRowsToDisplay(api.getModel().rowsToDisplay);
      }
      // Get active row from focused cell
      if (
        type === "firstDataRendered" ||
        type === "cellFocused" ||
        type === "modelUpdated"
      ) {
        const focusedCell = api.getFocusedCell() || {};
        const row = api.getDisplayedRowAtIndex(focusedCell.rowIndex) || {};
        setActiveRowNode(row);
      }
    });
  };

  // Column options defs
  const gridOptions = {
    defaultExportParams: {
      columnGroups: true
    },
    defaultColDef: {
      minWidth: 60
    },
    enableCellChangeFlash: true,
    rowDragManaged: urlParams.alternative !== DISPLAYS.hierarchy,
    suppressMoveWhenRowDragging: true,
    suppressRowClickSelection: true,
    rowGroupPanelShow: "onlyWhenGrouping", // on of ['always','onlyWhenGrouping']
    pivotPanelShow: "onlyWhenPivoting", // on of ['always','onlyWhenPivoting']
    pivotColumnGroupTotals: "before",
    pivotRowTotals: "before",
    enterMovesDownAfterEdit: true,
    multiSortKey: "ctrl",
    animateRows: true,
    resizable: true, //one of [true, false]
    enableStatusBar: true,
    enableRangeSelection: true,
    enableFillHandle: true,
    undoRedoCellEditing: true,
    undoRedoCellEditingLimit: 20,
    suppressClearOnFillReduction: true,
    rowSelection: "multiple", // one of ['single','multiple'], leave blank for no selection
    rowDeselection: true,
    quickFilterText: null,
    treeData: urlParams.alternative === DISPLAYS.hierarchy,
    getDataPath: data => data.sys_hierarchy || [data.id],
    autoGroupColumnDef: {
      headerName: hierarchyColumn.label || "Group",
      pinned: "left",
      lockPinned: true
    },
    stopEditingWhenGridLosesFocus: true,
    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel"
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel"
        },
        {
          id: "savedFilters",
          labelDefault: "Saved filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "customToolPanel",
          toolPanelParams: {
            code: "saved_filters",
            data: savedFilters
          }
        },
        ...additionalToolbars()
      ],
      position: "left"
    },
    //groupSelectsChildren: true, // one of [true, false]
    onRowSelected: rowSelected, //callback when row selected
    onSelectionChanged: selectionChanged, //callback when selection changed,
    onRowDragEnd: handleRowDragEnd,
    ...(urlParams.alternative === DISPLAYS.hierarchy && {
      onRowDragMove: onRowDragMove,
      onRowDragLeave: onRowDragLeave
    }),
    // onSortChanged: (params) => {
    //   params.api.refreshCells({ column: ['rowNumber'] });
    // },
    onCellValueChanged: onCellChange,
    onCellKeyPress: handleCellKeyPress,
    getContextMenuItems: getContextMenuItems,
    onCellMouseDown: handleCellClick,
    onFilterChanged: handleFilterChange
  };

  const handleWidget = (event, newWidget) => {
    if (newWidget === null) {
      setInspector(false);
    } else {
      setPicker(newWidget);
      setInspector(true);
    }
    setWidget(newWidget);
  };

  const handleFilter = (event = {}) => {
    switch (event.code) {
      case FILTER_OPTIONS.clearFilter:
        gridApi.setFilterModel({});
        break;
      case FILTER_OPTIONS.editFilter:
        gridApi.openToolPanel("filters");
        break;
      case FILTER_OPTIONS.saveFilter:
        saveFilters();
        break;
      default:
        return;
    }
  };

  const handleView = event => {
    setView(
      event.id
        ? event
        : event.alternative
        ? { ...view, alternative: event.code }
        : view
    );
    if (event.action) {
      // Do something with edit/copy/save view
      return;
    }
    history.push(
      event.id
        ? `?view=${event.id}`
        : event.alternative
        ? `?view=${view.id}&alternative=${event.code}`
        : `?view=${view.id}`
    );
  };

  const handleSelection = event => {
    const selectedRows = gridApi.getSelectedNodes();

    switch (event.code) {
      case SELECTION_OPTIONS.filterToSelect:
        break;
      case SELECTION_OPTIONS.clearSelected:
        gridApi.deselectAll();
        break;
      case SELECTION_OPTIONS.selectAll:
        gridApi.selectAll();
        break;
      case SELECTION_OPTIONS.invert:
        gridApi.selectAll();
        selectedRows.forEach(node => {
          node.setSelected(false);
        });
        break;
      default:
        return;
    }
  };

  const handleActionClick = event => {
    console.log("action click: ", event);
  };

  const handleButtonClick = event => {
    console.log("button click: ", event);
  };

  const handleChannel = (event, newChannel) => {
    setSelected(newChannel);
    getSelected();
  };

  const handleEditable = (params, colState) => {
    const { column, data } = params;
    const editableCols = SheetDataService.extractEditableCols(data, sets);

    return !colState.protect
      ? editableCols.length > 0
        ? editableCols.includes(column.colId)
        : true
      : !colState.protect;
  };

  const oHValueGetter = ({ api, data, node }) => {
    if (data.entity_row_id === null) return;

    let _levelTestsScore = 0;

    _cols.forEach(col => {
      const channelObj =
        (col.testable &&
          col.channels &&
          col.channels.find(c => c.channel_id === urlParams.channel)) ||
        {};
      let _nodes = [];

      api.forEachNode(rowNode => {
        rowNode.id !== node.id &&
          rowNode.data &&
          _nodes.push(rowNode.data[col.code]);
      });

      const isValidType = SheetDataService.testTypecast(
        data[col.code],
        col.typecast,
        sets[col.code]
      );

      channelObj.tests &&
        channelObj.tests.forEach(test => {
          const testPassed = SheetDataService.testValue(
            data[col.code],
            test,
            _nodes
          );

          if (!testPassed) {
            if (test.level === "critical") {
              _levelTestsScore += 1000;
            } else if (test.level === "warning") {
              _levelTestsScore += 1;
            }
          }
        });

      if (!isValidType) {
        _levelTestsScore += 1000;
      }
    });

    // Update server test score through webSocket
    //WSContext.addData(_levelTestsScore);

    return _levelTestsScore;
  };

  const handleTesting = ({ api, value, column, node }, colState) => {
    const colId = column ? column.colId : colState.code;

    const channelObj =
      (colState.testable &&
        colState.channels &&
        colState.channels.find(c => c.channel_id === urlParams.channel)) ||
      {};
    const isValidType = SheetDataService.testTypecast(
      value,
      colState.typecast,
      sets[colId]
    );
    let _levelTests = [];
    let _nodes = [];

    api.forEachNode(rowNode => {
      rowNode.id !== node.id &&
        rowNode.data &&
        _nodes.push(rowNode.data[colId]);
    });

    node.data.entity_row_id !== null &&
      channelObj.tests &&
      channelObj.tests.forEach(test => {
        const testPassed = SheetDataService.testValue(value, test, _nodes);

        if (!testPassed) {
          _levelTests.push({
            ...test,
            column: colState.name,
            cellValue: value
          });
        }
      });

    if (!isValidType) {
      _levelTests.push({
        code: "type",
        level: "critical",
        params: [{ value: colState.typecast }],
        column: colState.name,
        cellValue: value
      });
    }

    return _levelTests;
  };

  const handleWidgetInputChange = event => {
    const { name, value } = event.target;
    activeRowNode.setDataValue(name, value);
  };

  return (
    <AuthenticatedLayout
      title={intl.formatMessage({
        id: `sidebar.companies`,
        defaultMessage: humanize("Companies")
      })}
    >
      <Toolbar
        onFilterChange={handleFilter}
        onViewChange={handleView}
        onSelectionChange={handleSelection}
        onActionClick={handleActionClick}
        onButtonClick={handleButtonClick}
        onWidgetToggle={handleWidget}
        views={views}
        view={view}
        rowsToDisplay={rowsToDisplay.length}
        rows={_rows}
        widget={widget}
        entities={entities}
        selectedNumber={selectedRows.length}
        appliedFilters={appliedFilters}
      />

      <div
        className="ag-theme-balham"
        style={{
          width: "100%",
          height: "calc(100vh - 245px)"
        }}
      >
        {!gridDestroyed && (
          <AgGridReact
            onGridReady={onGridReady}
            rowData={_rows}
            immutableData={true}
            getRowNodeId={data => data.id}
            frameworkComponents={{
              // renderers
              booleanRenderer: BooleanRenderer,
              imageRenderer: ImageRenderer,
              currencyRenderer: CurrencyRenderer,
              textRenderer: TextRenderer,
              htmlRenderer: HtmlRenderer,
              overallHealthRenderer: OverallHealthRenderer,
              // editors
              dateEditor: DateEditor,
              calculatorEditor: CalculatorEditor,
              // toolbars
              customToolPanel: CustomToolPanel
            }}
            {...gridOptions}
          >
            <AgGridColumn
              headerName="#"
              field="rowNumber"
              width={75}
              rowDrag
              checkboxSelection
              headerCheckboxSelection
              headerCheckboxSelectionFilteredOnly
              //valueGetter={(params) => params.node.rowIndex + 1}
              suppressMenu
              suppressCellFlash
              suppressMovable
              suppressColumnsToolPanel
              editable={false}
              cellClass="lock-col" // add style for this class ( :disabled )
              lockPosition
              lockPinned
              pinned="left"
            />
            {_cols.map(col => (
              <AgGridColumn
                key={col.id}
                field={col.code}
                headerName={col.label}
                filter={FILTER_FROM_FILTER_TYPE[col.typecast]}
                //floatingFilter
                width={SheetDataService.getColWidth(col, view)}
                sortable
                editable={params => handleEditable(params, col)}
                resizable
                enableValue
                hide
                {...((col.display === DISPLAYS.overall_health ||
                  col.display === DISPLAYS.hierarchy) && {
                  pinned: "left",
                  lockPinned: true
                })}
                //floatCell
                cellRenderer={
                  RENDERER_FROM_DISPLAY[col.display] || "textRenderer"
                }
                cellRendererParams={{
                  channelData,
                  typecast: col.typecast,
                  cols: _cols,
                  onHtmlHelperClick: node => {
                    setHtmlEditorData(node);
                    setIsModalOpen(true);
                    setModalContent({ type: "htmlEditor" });
                  },
                  editable: params => handleEditable(params, col),
                  onTesting: (params, targetCol) =>
                    handleTesting(params, targetCol || col)
                }}
                //type={TYPE_FROM_TYPECAST[col.typecast]}
                cellEditorParams={{
                  cellRenderer:
                    RENDERER_FROM_DISPLAY[col.display] || "textRenderer",
                  values: SheetDataService.extractValues(sets[col.code]),
                  channelData
                }}
                cellEditorSelector={params => {
                  if (params.keyPress && params.keyPress === "helperBtn") {
                    return {
                      component: EDITOR_FROM_TYPECAST[col.typecast]
                    };
                  } else if (params.value && params.value.length > 30) {
                    return {
                      component: "agPopupTextCellEditor"
                    };
                  }
                }}
                cellStyle={params =>
                  cellStyleRules({
                    colState: col,
                    ...params
                  })
                }
                cellClassRules={{
                  good: params =>
                    cellClassRules({
                      colState: col,
                      sets,
                      channel: urlParams.channel,
                      ...params
                    }).good,
                  critical: params =>
                    cellClassRules({
                      colState: col,
                      sets,
                      channel: urlParams.channel,
                      ...params
                    }).critical,
                  warning: params =>
                    cellClassRules({
                      colState: col,
                      sets,
                      channel: urlParams.channel,
                      ...params
                    }).warning,
                  readOnly: params =>
                    cellClassRules({
                      colState: col,
                      sets,
                      channel: urlParams.channel,
                      ...params
                    }).readOnly
                }}
                valueFormatter={params =>
                  SheetDataService.lookupValue(
                    col.typecast,
                    sets[col.code],
                    params.value,
                    channelData
                  )
                }
                valueParser={params =>
                  SheetDataService.lookupKey(
                    col.typecast,
                    sets[col.code],
                    params.newValue
                  )
                }
                {...(col.display === DISPLAYS.overall_health && {
                  valueGetter: oHValueGetter
                })}
                suppressCellFlash={
                  col.typecast === TYPECASTS.boolean ||
                  col.code === DISPLAYS.overall_health
                }
                //suppressKeyboardEvent={(params) => suppressSpace(params, col)}
              />
            ))}
          </AgGridReact>
        )}
      </div>
      <Paper square>
        <Tabs
          value={channelData.position || 0}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChannel}
        >
          <Tab label="Core" />
          <Tab label="English" disabled />
          <Tab label="Danish" />
        </Tabs>
      </Paper>

      <Widget
        open={inspector}
        picker={picker}
        rowNode={activeRowNode}
        onClose={() => setInspector(false)}
        onChange={handleWidgetInputChange}
      />

      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        isDialog={modalContent.isDialog}
      >
        {renderModalContent(modalContent.type, modalContent.params)}
      </Modal>
    </AuthenticatedLayout>
  );
}

function moveToPath(newParentPath, node, allUpdatedNodes) {
  const oldPath = node.data.sys_hierarchy || [node.data.id];
  const fileName = oldPath[oldPath.length - 1];
  const newChildPath = newParentPath.slice();
  newChildPath.push(fileName);
  node.data.sys_hierarchy = newChildPath;
  allUpdatedNodes.push(node.data);
  if (node.childrenAfterGroup) {
    node.childrenAfterGroup.forEach(function(childNode) {
      moveToPath(newChildPath, childNode, allUpdatedNodes);
    });
  }
}
function isSelectionParentOfTarget(selectedNode, targetNode) {
  const children = selectedNode.childrenAfterGroup;
  for (var i = 0; i < children.length; i++) {
    if (targetNode && children[i].key === targetNode.key) return true;
    isSelectionParentOfTarget(children[i], targetNode);
  }
  return false;
}
function arePathsEqual(path1 = [], path2 = []) {
  if (path1.length !== path2.length) {
    return false;
  }
  let equal = true;
  path1.forEach(function(item, index) {
    if (path2[index] !== item) {
      equal = false;
    }
  });
  return equal;
}
function setPotentialParentForNode(api, overNode) {
  let newPotentialParent;
  if (overNode) {
    newPotentialParent = overNode.data.sys_hierarchy
      ? overNode
      : overNode.parent;
  } else {
    newPotentialParent = null;
  }
  const alreadySelected = potentialParent === newPotentialParent;
  if (alreadySelected) {
    return;
  }
  const rowsToRefresh = [];
  if (potentialParent) {
    rowsToRefresh.push(potentialParent);
  }
  if (newPotentialParent) {
    rowsToRefresh.push(newPotentialParent);
  }
  potentialParent = newPotentialParent;
  refreshRows(api, rowsToRefresh);
}
function refreshRows(api, rowsToRefresh) {
  const params = {
    rowNodes: rowsToRefresh,
    force: true
  };
  api.refreshCells(params);
}
function updateLocalRows(api) {
  const _newNodes = [];
  api.forEachNode(node => {
    _newNodes.push(node.data);
  });
  _rows = _newNodes;
}

export default injectIntl(Products);
