import React from "react";
import Button from "@material-ui/core/Button";

const getProps = role =>
  ({
    primary: {
      variant: "contained",
      color: "primary"
    },
    secondary: {
      variant: "outlined"
    }
  }[role]);

export default ({ ui = "primary", ...props }) => (
  <Button {...getProps(ui)} {...props} />
);
