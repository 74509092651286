import React from "react";
import Document from "./Document";
import Workbench from "./Workbench";

const AuthenticatedLayout = ({ title = "Sheetly", children }) => (
  <Document>
    <Workbench title={title}>{children}</Workbench>
  </Document>
);

export default AuthenticatedLayout;
