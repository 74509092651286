import React from "react";

export default ({ label, value, placeholder = "-", children }) => (
  <div className="preview">
    <label htmlFor={label}>{label}</label>
    <div style={{ display: "flex" }}>
      <div className="value">{value || placeholder}</div>
      <div>{children}</div>
    </div>
  </div>
);
