import React from "react";
import { Link } from "react-router-dom";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

export default ({ to }) => (
  <Link to={to}>
    <Fab color="primary" aria-label="add">
      <AddIcon />
    </Fab>
  </Link>
);
