import React from "react";
import { LinkButton, Toolbar } from "components/ui";
import withData from "lib/withData";
import { Activity } from "models";
import { AuthenticatedLayout } from "../../components/app";

const getInitialProps = async ({ db }) => {
  const activities = await Activity(db).all();

  return {
    activities
  };
};

const Index = ({ loading, data }) => {
  return (
    <AuthenticatedLayout title="Activities">
      <Toolbar>
        <LinkButton size="small" ui="secondary" to="/activities">
          Refresh
        </LinkButton>
      </Toolbar>
      <table>
        <thead>
          <tr>
            <th>User</th>
            <th>Activity</th>
            <th>Cover</th>
          </tr>
        </thead>
        <tbody>
          {loading && <p>Loading...</p>}

          {!loading &&
            data.activities.map(activity => (
              <tr key={activity.id}>
                <td>
                  <img
                    src={activity.avatar}
                    alt={activity.user}
                    style={{
                      width: "32px",
                      height: "32px",
                      borderRadius: "50%",
                      marginRight: "0.5em"
                    }}
                  />
                </td>
                <td>{activity.type}</td>
                <td>{activity.name}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </AuthenticatedLayout>
  );
};

export default withData(Index, getInitialProps);
