import React from "react";
import { Form } from "lib/Form";

export default ({ resource, initialValues, children, onSubmit, ...props }) => (
  <Form
    resource={resource}
    initialValues={initialValues}
    onSubmit={values => onSubmit({ values })}
    {...props}
  >
    {children}
  </Form>
);
