import React from "react";
//import { Button, Icon, H3, H5 } from "components/ui";
//import { Card, withStyles, CardContent, CardHeader, Link } from "@material-ui/core";
import { SidebarLayout } from "components/app";
//import { StitchContext } from 'stitch';
import Lock from "components/app/Lock";

// const GoogleButton = withStyles((theme) => ({
//   root: {
//     color: theme.palette.getContrastText('#4285F4'),
//     backgroundColor: '#4285F4',
//     '&:hover': {
//       backgroundColor: '#4285F4',
//     },
//   },
// }))(Button);

// const FBButton = withStyles((theme) => ({
//   root: {
//     color: theme.palette.getContrastText('#4267B2'),
//     backgroundColor: '#4267B2',
//     '&:hover': {
//       backgroundColor: '#4267B2',
//     },
//   },
// }))(Button);

export default () => {
  return (
    <SidebarLayout>
      <Lock />
      {/*
      <StitchContext.Consumer>
        {({ loginGoogle, loginFacebook }) => (
          <div style={{ textAlign: 'center' }}>
            <div style={{ paddingBottom: '20px' }}>
              <H3>Welcome back.</H3>
            </div>
            <div>
              <GoogleButton
                ui="primary"
                style={{ width: '250px' }}
                onClick={loginGoogle}
              >
                <Icon size="24" line="1" stroke="white" glyph="google" />
                <div style={{ color: '#fff', paddingLeft: '15px' }}>
                  sign in with Google
                </div>
              </GoogleButton>
            </div>
            <div style={{ paddingTop: '10px' }}>
              <FBButton
                ui="primary"
                disabled
                style={{ width: '250px' }}
                onClick={loginFacebook}
              >
                <Icon size="24" line="1" stroke="white" glyph="facebook" />
                <div style={{ color: '#fff', paddingLeft: '15px' }}>
                  sign in with Facebook
                </div>
              </FBButton>
            </div>
            <div style={{ paddingBottom: '10px', paddingTop: '40px' }}>
              <H5>Or, sign in with your email.</H5>
            </div>
            <div
              style={{
                paddingTop: '20px',
                paddingBottom: '20px',
              }}
            >
              <Card style={{ width: '250px', margin: 'auto' }}>
                <CardHeader />
                <CardContent style={{ height: 100 }}>
                  <div>coming soon!</div>
                </CardContent>
              </Card>
            </div>
            <div>
              <Button
                ui="primary"
                style={{ width: '250px' }}
                onClick={handleLogin}
              >
                <Icon size="24" line="1" stroke="white" glyph="banana" />
                <div style={{ color: '#fff', paddingLeft: '15px' }}>
                  sign in with Sheetly
                </div>
              </Button>
            </div>
            <div>
              <Link href="/passwordreset">Forgot your password?</Link>
            </div>
          </div>
        )}
            </StitchContext.Consumer> */}
    </SidebarLayout>
  );
};
