import gql from "graphql-tag";

export default gql`
  query {
    connectors {
      id
      code
      name
      logo
      apiPath
    }
  }
`;
