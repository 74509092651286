import gql from "graphql-tag";

export default gql`
  query {
    tools {
      id
      code
      name
      toolbarName
      description
      image
      icon
      isInProduction
      readMoreUrl
      position
      inToolbar
      tags {
        name
        colour
        code
      }
    }
  }
`;
