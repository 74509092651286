import React from "react";
import { toast } from "react-toastify";
import { Toolbar, LinkButton } from "components/ui";
import { AuthenticatedLayout, ResourceForm } from "components/app";
import { Field } from "lib/Form";
import withData from "lib/withData";
import { Book } from "models";

const Loading = () => (
  <AuthenticatedLayout title="Loading book details...">
    <p>Loading...</p>
  </AuthenticatedLayout>
);

const getInitialProps = async ({ db, params }) => {
  const book = await Book(db).find(params.id);

  const update = async ({ id, values }) => {
    await Book(db).update(id, values);
    toast("Book updated");
  };

  return {
    book,
    update
  };
};

const Edit = ({ loading, data }) => {
  if (loading) return <Loading />;

  const { book, update } = data;

  return (
    <AuthenticatedLayout title={`Edit ${book.title}`}>
      <Toolbar>
        <LinkButton ui="primary" color="primary" to="/books">
          Back to list
        </LinkButton>
      </Toolbar>

      <ResourceForm
        resource="book"
        initialValues={book}
        autosave
        onSubmit={({ values }) => update({ id: book.id, values })}
      >
        {() => (
          <>
            <Field name="title" />
            <Field name="author" />
          </>
        )}
      </ResourceForm>
    </AuthenticatedLayout>
  );
};

export default withData(Edit, getInitialProps);
