import React from "react";
import FormContext from "./FormContext";
import { Button } from "../../components/ui";

export default ({ children, ...props }) => (
  <FormContext.Consumer>
    {({ isSubmitting }) => (
      <Button ui="primary" type="submit" disabled={isSubmitting} {...props}>
        {children}
      </Button>
    )}
  </FormContext.Consumer>
);
