import auth0 from "auth0-js";
import jwtDecode from "jwt-decode";
import getConfig from "../getConfig";
import { Registry } from "lib";
import { User, Account } from "models";

import { user } from "auth/dummyData";

export default class Auth {
  constructor() {
    const { domain, auth } = getConfig();

    this.auth0 = new auth0.WebAuth({
      domain: auth.domain,
      clientID: auth.clientID,
      redirectUri: domain,
      responseType: "token id_token",
      scope: "openid email profile"
    });
  }

  login(credentials) {
    this.auth0.login(credentials);
  }

  static getToken() {
    return localStorage.getItem("idToken");
  }

  static getExpiration() {
    return localStorage.getItem("expiresAt");
  }

  static isAuthenticated() {
    return new Date().getTime() < Auth.getExpiration();
  }

  static getProfile() {
    try {
      return jwtDecode(Auth.getToken());
    } catch {
      return null;
    }
  }

  static async getCurrentUser() {
    let userCache = Registry.get("user");

    if (!userCache) {
      Registry.add("user", user);
      userCache = user;
    }
    return userCache;
  }

  static fromLock(authResult) {
    return new Promise((resolve, reject) => {
      if (!authResult || !authResult.idToken) {
        return reject(new Error("No token found in authResult"));
      }

      localStorage.setItem("idToken", authResult.idToken);
      localStorage.setItem(
        "expiresAt",
        authResult.expiresIn * 1000 + new Date().getTime()
      );

      return resolve();
    });
  }

  logout() {
    localStorage.removeItem("idToken");
    localStorage.removeItem("expiresAt");
    this.auth0.logout({
      returnTo: window.location.origin
    });
  }
}
