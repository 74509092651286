/* eslint-disable no-undef */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React from "react";
import { toast } from "react-toastify";
import { Toolbar, LinkButton, Button } from "components/ui";
import { AuthenticatedLayout } from "components/app";
import withData from "lib/withData";
import { Book } from "models";

const Loading = () => (
  <AuthenticatedLayout title="Loading book details...">
    <p>Loading...</p>
  </AuthenticatedLayout>
);

const getInitialProps = async ({ db, params, history }) => {
  const book = await Book(db).find(params.id);

  const deleteRecord = async ({ id }) => {
    await Book(db).destroy(id);

    history.push("/books");
    toast("Book was deleted");
  };

  return {
    book,
    deleteRecord
  };
};

const Show = ({ loading, data }) => {
  if (loading) return <Loading />;

  const { book, deleteRecord } = data;

  return (
    <AuthenticatedLayout title={`Details for ${book.title}`}>
      <Toolbar>
        <LinkButton ui="primary" to={`/book/${book.id}/edit`}>
          Edit
        </LinkButton>

        <Button
          ui="primary"
          onClick={() => {
            if (confirm("Are you sure?")) deleteRecord({ id: book.id });
          }}
        >
          Delete
        </Button>

        <LinkButton ui="primary" color="primary" to="/books">
          Back to list
        </LinkButton>
      </Toolbar>

      <pre>{JSON.stringify(book, null, 2)}</pre>
    </AuthenticatedLayout>
  );
};

export default withData(Show, getInitialProps);
