import React, { useState } from "react";
import { Toolbar, Icon } from "components/ui";
import { injectIntl } from "react-intl";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { Input } from ".";
import { VIEW_OPTIONS, FILTER_OPTIONS, SELECTION_OPTIONS } from "lib/constants";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export default injectIntl(
  ({
    intl,
    onFilterChange,
    onViewChange,
    onSelectionChange,
    onActionClick,
    onButtonClick,
    onWidgetToggle,
    view,
    views,
    rowsToDisplay,
    rows,
    widget,
    entities = {},
    selectedNumber = 0,
    appliedFilters = {}
  }) => {
    const filterOptions = [
      {
        code: FILTER_OPTIONS.clearFilter,
        name: "Clear filter"
      },
      {
        code: FILTER_OPTIONS.editFilter,
        name: "Edit filter"
      },
      {
        code: FILTER_OPTIONS.saveFilter,
        name: "Save filter"
      }
    ];

    const selectionOptions = [
      {
        code: SELECTION_OPTIONS.filterToSelect,
        name: "Filter to selected rows",
        disabled: selectedNumber === 0
      },
      {
        code: SELECTION_OPTIONS.clearSelected,
        name: `Clear ${selectedNumber} selected rows`,
        disabled: selectedNumber === 0
      },
      {
        code: SELECTION_OPTIONS.selectAll,
        name: `Select all ${rowsToDisplay} rows`
      },
      {
        code: SELECTION_OPTIONS.invert,
        name: `Invert selected`,
        disabled: selectedNumber === 0
      }
    ];

    const viewOptions = [
      {
        code: VIEW_OPTIONS.editView,
        name: "Edit view",
        action: true
      },
      {
        code: VIEW_OPTIONS.copyView,
        name: "Copy view",
        action: true
      },
      {
        code: VIEW_OPTIONS.saveView,
        name: "Save view",
        action: true
      }
    ];

    const generateViewOptions = () => {
      let options = [...(views || []), { element: "divider" }];

      entities.views &&
        entities.views.forEach(view => {
          options.push({
            code: view.alternative_view_code,
            name: view.alternative_view_name,
            alternative: true
          });
        });

      options.push({ element: "divider" }, ...viewOptions);

      return options;
    };

    const getOptionName = view => {
      const option = generateViewOptions().find(option => option.code === view);
      return option ? option.name : view;
    };

    return (
      <Toolbar>
        {entities.show_limiters && (
          <Input
            type="menu"
            id="limiters"
            label={intl.formatMessage({
              id: `products.toolbar.${appliedFilters.label}`,
              defaultMessage: "No filtering"
            })}
            options={filterOptions}
            onChange={onFilterChange}
            disabled={Object.keys(appliedFilters).length === 0}
          />
        )}
        <span> | </span>
        <span>
          {rowsToDisplay} of {rows.length}
        </span>
        <span> | </span>
        {entities.show_views && (
          <Input
            type="menu"
            id="views"
            label={
              view.name && !view.alternative
                ? view.name
                : getOptionName(view.alternative)
            }
            options={generateViewOptions()}
            onChange={onViewChange}
          />
        )}
        <span> | </span>
        {entities.show_selected && (
          <Input
            type="menu"
            id="selections"
            label={`${selectedNumber} selected`}
            options={selectionOptions}
            onChange={onSelectionChange}
          />
        )}
        <span> | </span>
        {entities.show_actions && (
          <Input
            type="menu"
            id="actions"
            label="Actions"
            options={entities.actions}
            onChange={onActionClick}
            disabled={selectedNumber === 0}
          />
        )}
        <span> | </span>
        {entities.show_buttons &&
          entities.buttons.map(button => (
            <Input
              type="button"
              key={button.button_code}
              id={button.code}
              label={button.button_name}
              icon={button.button_icon}
              onClick={() => onButtonClick(button.button_code)}
            />
          ))}
        <span> | </span>
        <ToggleButtonGroup
          size="small"
          value={widget}
          exclusive
          onChange={onWidgetToggle}
        >
          {entities.relationships &&
            entities.relationships.map(toggle => (
              <ToggleButton
                key={toggle.code}
                title={toggle.name}
                value={toggle.widget_code}
              >
                {toggle.widget_icon && (
                  <img src={toggle.widget_icon} alt={toggle.name} />
                )}
                {/* <Icon size="18" line="0" glyph="stethoscope" /> */}
              </ToggleButton>
            ))}
          {/*
        <ToggleButton title="Category widget" value="categoryWidget">
          <Icon size="18" line="0" glyph="treeview" />
        </ToggleButton>
        <ToggleButton title="Related Product widget" value="relatedWidget">
          <Icon size="18" line="0" glyph="related" />
        </ToggleButton>
        <ToggleButton title="Up Sale Product widget" value="upsaleWidget">
          <Icon size="18" line="0" glyph="upsale" />
        </ToggleButton>
        <ToggleButton title="Cross Sale Product widget" value="crosssaleWidget">
          <Icon size="18" line="0" glyph="crosssale" />
        </ToggleButton>
        <ToggleButton title="Asset widget" value="assetWidget">
          <Icon size="18" line="0" glyph="image" />
        </ToggleButton>
        <ToggleButton title="Tag widget" value="tagWidget">
          <Icon size="18" line="0" glyph="tags" />
              </ToggleButton> */}
        </ToggleButtonGroup>
      </Toolbar>
    );
  }
);
