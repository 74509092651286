import React from "react";
import { Mutation } from "react-apollo";
import { Button } from "components/ui";

const MutationButton = ({
  children,
  mutation,
  variables,
  mutationProps,
  buttonProps,
  onClick
}) => (
  <Mutation mutation={mutation} variables={variables} {...mutationProps}>
    {mutate => (
      <Button {...buttonProps} onClick={() => onClick(mutate)}>
        {children}
      </Button>
    )}
  </Mutation>
);

MutationButton.defaultProps = {
  buttonProps: { ui: "primary", color: "secondary" }
};

export default MutationButton;
