import { ObjectId } from "bson";
import { Registry, serialize } from "lib";

export default {
  all: async () => {
    const db = Registry.get("db");
    return serialize(
      await db
        .db("sheetly_seed")
        .collection("tools")
        .find({})
        .asArray()
    );
  },
  find: async id => {
    const db = Registry.get("db");
    return serialize(
      await db
        .db("sheetly_seed")
        .collection("tools")
        .findOne({ _id: new ObjectId(id) })
    );
  }
};
