import React from "react";
import { TYPECASTS } from "lib/constants";

export default function HelperButton({ type, onClick }) {
  switch (type) {
    case TYPECASTS.select:
      return (
        <span
          title="select helper"
          className="ag-icon ag-icon-group content-icon helper-icon"
          onClick={onClick}
        />
      );
    case TYPECASTS.date:
      return (
        <span
          title="date helper"
          className="ag-icon ag-icon-grip content-icon helper-icon"
          onClick={onClick}
        />
      );
    case TYPECASTS.boolean:
      return (
        <span
          title="boolean helper"
          className="ag-icon ag-icon-grip content-icon helper-icon"
          onClick={onClick}
        />
      );
    case TYPECASTS.html:
      return (
        <span
          title="boolean helper"
          className="ag-icon ag-icon-grip content-icon helper-icon"
          onClick={onClick}
        />
      );
    case TYPECASTS.currency:
      return (
        <span
          title="boolean helper"
          className="ag-icon ag-icon-grip content-icon helper-icon"
          onClick={onClick}
        />
      );
    case TYPECASTS.integer:
      return (
        <span
          title="boolean helper"
          className="ag-icon ag-icon-grip content-icon helper-icon"
          onClick={onClick}
        />
      );
    case "test":
      return (
        <span
          title=""
          className="ag-icon ag-icon-eye content-icon helper-icon"
          onClick={onClick}
        />
      );
    default:
      return null;
  }
}
