class Registry {
  constructor() {
    this._data = {};
  }

  add(key, value) {
    this._data[key] = value;
  }

  get(key, defaultValue = null) {
    return this._data[key] || defaultValue;
  }
}

const instance = new Registry();
Object.freeze(instance);

export default instance;
