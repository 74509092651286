import gql from "graphql-tag";

export default gql`
  query {
    channels {
      id
      code
      name
      icon
      position
      language
      currency
      currency_name
      connector
      flag
    }
  }
`;
