import React from "react";
// import { FormattedMessage } from 'react-intl';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import { Button } from '../components/ui';
//import { StitchContext } from "../stitch";

import Products from "../screens/products";
import Dashboards from "../screens/dashboards";

import Tools from "../screens/tools";
import Activities from "../screens/activities";
import Promos from "../screens/promos";
// import Onboarding from '../screens/onboarding';

import BooksIndex from "../screens/books";
import BooksShow from "../screens/books/show";
import BooksNew from "../screens/books/new";
import BooksEdit from "../screens/books/edit";

import CSVImport from "../screens/csvimport";
import CSVExport from "../screens/csvexport";
import BCConnect from "../screens/bcconnect";
import Sync from "../screens/sync";
import CreateAttribute from "../screens/createAttribute";

import Profile from "../screens/profile";
import Account from "../screens/account";
import Billing from "../screens/billing";
import Users from "../screens/users";
import Entities from "../screens/entities";
import Attributes from "../screens/attributes";
import Channels from "../screens/channels";
import Connectors from "../screens/connectors";
import Infocards from "../screens/infocards";
import Tests from "../screens/tests";
import Sets from "../screens/sets";
import Views from "../screens/views";
import Filters from "../screens/filters";
import Summaries from "../screens/summaries";
import Toolbarbuttons from "../screens/toolbarbuttons";
import Tags from "../screens/tags";
import Roles from "../screens/roles";

// const NotVerified = () => (
//   <div>
//     <h1>
//       <FormattedMessage
//         id="login.notverified.title"
//         defaultMessage="Email is not verified"
//       />
//     </h1>
//     <p>
//       <FormattedMessage
//         id="login.notverified.instruction"
//         defaultMessage="Please follow up the link in your inbox."
//       />
//     </p>
//     <Button ui="primary" onClick={() => new Auth().logout()}>
//       Login again
//     </Button>
//   </div>
// );

const NoMatch = ({ location }) => (
  <div>
    <h3>
      No match for <code>{location.pathname}</code>
    </h3>
  </div>
);

export default () => (
  <Router>
    {/* if (!emailVerified) return <NotVerified />;
        if (!onboarded) return <Onboarding />; */}
    <Switch>
      <Route exact path="/" component={Products} />
      <Route exact path="/dashboards" component={Dashboards} />
      <Route
        exact
        path="/products/:sheet?/:channel?/:view?"
        component={Products}
      />
      <Route exact path="/activities" component={Activities} />
      <Route exact path="/whatsnew" component={Promos} />
      <Route exact path="/tools" component={Tools} />

      {/* Temporary routes - how do we do them dynamic? */}
      <Route exact path="/assets" component={Products} />
      <Route exact path="/categories" component={Products} />
      <Route exact path="/companies" component={Products} />

      {/* Temporary routes - sample screens? */}
      <Route exact path="/books" component={BooksIndex} />
      <Route exact path="/books/new" component={BooksNew} />
      <Route exact path="/book/:id/edit" component={BooksEdit} />
      <Route exact path="/book/:id" component={BooksShow} />

      {/* tool routes */}
      <Route exact path="/csvimport" component={CSVImport} />
      <Route exact path="/csvexport" component={CSVExport} />
      <Route exact path="/bcconnect" component={BCConnect} />
      <Route exact path="/sync" component={Sync} />
      <Route exact path="/create/attribute" component={CreateAttribute} />

      {/* settings routes */}
      <Route exact path="/settings" component={Profile} />
      <Route exact path="/settings/profile" component={Profile} />
      <Route exact path="/settings/account" component={Account} />
      <Route exact path="/settings/billing" component={Billing} />
      <Route exact path="/settings/infocards" component={Infocards} />
      <Route exact path="/settings/users" component={Users} />
      <Route exact path="/settings/roles" component={Roles} />
      <Route exact path="/settings/entities" component={Entities} />
      <Route exact path="/settings/attributes" component={Attributes} />
      <Route exact path="/settings/channels" component={Channels} />
      <Route exact path="/settings/connectors" component={Connectors} />
      <Route exact path="/settings/tests" component={Tests} />
      <Route exact path="/settings/sets" component={Sets} />
      <Route exact path="/settings/views" component={Views} />
      <Route exact path="/settings/filters" component={Filters} />
      <Route exact path="/settings/tags" component={Tags} />
      <Route exact path="/settings/cards" component={Summaries} />
      <Route exact path="/settings/bookmarks" component={Toolbarbuttons} />
      {/* 404 */}
      <Route component={NoMatch} />
    </Switch>
  </Router>
);
