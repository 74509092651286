/* eslint-disable no-undef */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React from "react";
import MUIDataTable from "mui-datatables";
import { toast } from "react-toastify";
import { Toolbar, Panel, H5, Button, Modal } from "components/ui";
import {
  AuthenticatedLayout,
  InspectorBar,
  ResourceForm
} from "components/app";
import { Field, Submit } from "lib/Form";
import { Book } from "models";

const Loading = () => (
  <AuthenticatedLayout title="Loading books...">
    <p>Loading...</p>
  </AuthenticatedLayout>
);

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "stacked",
  selectableRows: "none",
  print: false,
  download: false,
  elevation: 0
};

export default class BooksIndex extends React.Component {
  state = {
    loading: true,
    items: [],
    current: null,
    open: false
  };

  componentWillMount = async () => {
    await this.fetch();
  };

  resource = () => Book;

  fetch = async () => {
    const items = await this.resource().all();
    this.setState({ items, loading: false, open: false });
  };

  create = async ({ values }) => {
    await Book.create(values);
    await this.fetch();

    toast("Book was created");
  };

  update = async ({ id, values }) => {
    await this.resource().update(id, values);
    await this.fetch();

    toast("Book updated");
  };

  deleteRecord = async ({ id }) => {
    await this.resource().destroy(id);
    await this.fetch();

    toast("Book was deleted");
  };

  render() {
    const { loading, items, current, open } = this.state;

    if (loading) return <Loading />;

    const currentRecord = current ? items.find(b => b.id === current) : null;

    return (
      <AuthenticatedLayout title="Books">
        <Toolbar>
          <Button ui="primary" onClick={() => this.setState({ open: true })}>
            New book
          </Button>
        </Toolbar>

        <Panel>
          <MUIDataTable
            data={items}
            columns={[
              {
                name: "id",
                label: "Id",
                options: {
                  filter: false,
                  display: "excluded"
                }
              },
              {
                name: "title",
                label: "Title"
              },
              {
                name: "author",
                label: "Author"
              }
            ]}
            options={{
              ...options,
              onRowClick: row => this.setState({ current: row[0] })
            }}
          />
        </Panel>

        {currentRecord && (
          <InspectorBar open={current} size="small">
            <H5>Inspector</H5>
            <p>Detail view of {currentRecord.title}</p>
            <Toolbar>
              <Button
                ui="secondary"
                color="secondary"
                size="small"
                onClick={() => {
                  if (confirm("Are you sure?"))
                    this.deleteRecord({ id: currentRecord.id });
                }}
              >
                Delete
              </Button>

              <Button
                ui="secondary"
                color="secondary"
                size="small"
                onClick={() => this.setState({ current: null })}
              >
                Close
              </Button>
            </Toolbar>

            <ResourceForm
              resource="book"
              initialValues={currentRecord}
              autosave
              onSubmit={({ values }) =>
                this.update({ id: currentRecord.id, values })
              }
            >
              {() => (
                <>
                  <Field name="title" />
                  <Field name="author" />
                </>
              )}
            </ResourceForm>
          </InspectorBar>
        )}

        <Modal open={open} onClose={() => this.setState({ open: false })}>
          <H5>Create new book</H5>

          <ResourceForm
            resource="book"
            initialValues={{
              title: "",
              author: ""
            }}
            onSubmit={({ values }) => this.create({ values })}
          >
            {() => (
              <>
                <Field name="title" />
                <Field name="author" />

                <Submit>Save</Submit>
                <Button
                  ui="secondary"
                  onClick={() => this.setState({ open: null })}
                  style={{ marginLeft: "0.5em" }}
                >
                  Cancel
                </Button>
              </>
            )}
          </ResourceForm>
        </Modal>
      </AuthenticatedLayout>
    );
  }
}
