import React from "react";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import CssBaseline from "@material-ui/core/CssBaseline";
import "react-toastify/dist/ReactToastify.css";
import "../../styles.css";

export default ({ children }) => (
  <React.Fragment>
    <Helmet>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500"
      />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/react-datepicker/2.8.0/react-datepicker.min.css"
      />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
      />

      <CssBaseline />
    </Helmet>

    <div>{children}</div>

    <ToastContainer autoClose={3000} />
  </React.Fragment>
);
