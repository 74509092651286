import gql from "graphql-tag";

export default gql`
  query {
    tags {
      id
      code
      name
      desc
      type
      count
      locked
      private
      owner
      colour
    }
  }
`;
