import React from "react";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia
} from "@material-ui/core";
import { H5, P, LinkButton } from "../../components/ui";

export default ({ image, title, description, path, launchdate, readurl }) => (
  <Card>
    <CardActionArea>
      <CardContent style={{ height: 100 }}>
        <H5>{title}</H5>
        <span>{launchdate}</span>
      </CardContent>
      <CardMedia style={{ height: 350 }} image={image} title={title} />
      <CardContent style={{ height: 100 }}>
        <P>{description}</P>
      </CardContent>
    </CardActionArea>
    <CardActions>
      <LinkButton size="small" to={`/${path}`} ui="secondary">
        Try it out
      </LinkButton>
      <LinkButton size="small" to={readurl} ui="secondary" target="_blank">
        Read More
      </LinkButton>
    </CardActions>
  </Card>
);
