import React from "react";
import { Warning as AlertIcon } from "@material-ui/icons";

export default ({ children }) => (
  <div
    style={{
      display: "flex",
      alignitems: "center",
      padding: "1em",
      backgroundColor: "#e1f5fe",
      color: "#01579b"
    }}
  >
    <AlertIcon style={{ marginRight: "0.5em" }} />
    <span style={{ fontWeight: 500 }}>{children}</span>
  </div>
);
