import React, { useState } from "react";
import { IMAGES_PATH } from "lib/constants";
import { useTestHelper } from "components/app/grid";

export default function ImageRenderer({
  value,
  api,
  column,
  node,
  data,
  onTesting,
  intl
}) {
  const [isHelperVisible, setIsHelperVisible] = useState(false);
  const testTooltip = useTestHelper({
    value,
    data,
    api,
    node,
    column,
    onTesting,
    intl
  });

  if (!value) {
    return "";
  }

  //const validUrl = SheetDataService.validURL(value);
  //const htmlEscaped = SheetDataService.stripHtmlTags(value);

  const handleMouseEnter = () => {
    setIsHelperVisible(true);
  };

  const handleMouseLeave = () => {
    setIsHelperVisible(false);
  };

  return (
    <>
      <div
        className="ag-cell-container ag-cell-with-helper"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {Array.isArray(value) ? (
          value.map((val, index) => (
            <img
              key={index}
              className="cell-image"
              height="22px"
              src={`${IMAGES_PATH.thumbnail}/${val}`}
              alt={value}
            />
          ))
        ) : (
          <img
            className="cell-image"
            height="22px"
            src={`${IMAGES_PATH.thumbnail}/${value}`}
            alt={value}
          />
        )}
        <div className="cell-helpers-wrapper">
          {isHelperVisible && testTooltip}
        </div>
      </div>
    </>
  );
}
