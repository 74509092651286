import React, { useState } from "react";
import { Button, Toolbar, H5, Icon, Panel } from "components/ui";
import { SettingsLayout, InspectorBar, Link } from "components/app";
import withData from "lib/withData";
import { Tag } from "models";

const getInitialProps = async ({ db, user }) => {
  const tags = await Tag(db).all(user.ownerId);

  return {
    tags
  };
};

const Index = ({ loading, data }) => {
  const [inspector, setInspector] = useState(false);
  const [current] = useState(null);

  return (
    <SettingsLayout title="Tags">
      <Link to="http://support.sheetly.io" target="_blank">
        <Icon size="18" line="1" glyph="brain" />
        Learn more about Tags
      </Link>

      <Toolbar>
        <Button
          ui="secondary"
          size="small"
          onClick={() => setInspector(!inspector)}
        >
          Inspector
        </Button>
      </Toolbar>

      <Panel>
        <table size="small">
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Desc</th>
              <th>Type</th>
              <th>Count</th>
              <th>Locked</th>
              <th>Colour</th>
              <th>Private</th>
            </tr>
          </thead>
          <tbody>
            {loading && <p>Loading...</p>}

            {!loading &&
              data.tags.map(tag => (
                <tr key={tag.id}>
                  <td>{tag.code}</td>
                  <td>{tag.name}</td>
                  <td>{tag.desc}</td>
                  <td>{tag.type}</td>
                  <td>{tag.count}</td>
                  <td>{tag.locked}</td>
                  <td>{tag.colour}</td>
                  <td>{tag.private}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Panel>
      <InspectorBar open={inspector} size="small">
        <H5>Inspector</H5>
        <p>Detail view of ...</p>
        <Toolbar>
          <Button
            ui="secondary"
            size="small"
            onClick={() => setInspector(false)}
          >
            Edit
          </Button>
          <Button
            ui="secondary"
            color="secondary"
            size="small"
            onClick={() => setInspector(false)}
          >
            Delete
          </Button>
        </Toolbar>
        <Panel>{current && <p>Detail view of ...</p>}</Panel>
      </InspectorBar>
    </SettingsLayout>
  );
};

export default withData(Index, getInitialProps);
