import gql from "graphql-tag";

export default gql`
  query {
    views {
      id
      entityGroup
      name
      owner
      desc
    }
  }
`;
