import React, { useEffect, useState } from "react";
import { Input } from ".";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox
} from "@material-ui/core";
import { TOOLBAR_FILTER_TYPE } from "lib/constants";

const RenderSavedFilters = ({ data = [], onClick, appliedFilters = {} }) => {
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    if (Object.keys(appliedFilters).length === 0) {
      setChecked([]);
    }
  }, [appliedFilters]);

  const handleClick = filter => () => {
    const currentIndex = checked.indexOf(filter.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(filter.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    onClick(filter, TOOLBAR_FILTER_TYPE.tempFilter);
  };

  return (
    <List>
      {data.map(filter => (
        <ListItem
          key={filter.id}
          dense
          button
          disableRipple
          onClick={handleClick(filter)}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              color="primary"
              checked={checked.indexOf(filter.id) !== -1}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": filter.id }}
            />
          </ListItemIcon>
          <ListItemText id={filter.id} primary={filter.name} />
        </ListItem>
      ))}
    </List>
  );
};

const RenderCategoryFilters = ({ data = [], code, onClick }) => {
  const filter = data.find(filter => filter.code === code) || {};
  return (
    <List>
      <ListItem button onClick={onClick}>
        <ListItemText primary={filter.name} />
      </ListItem>
    </List>
  );
};

const RenderTagFilters = ({ data = [], code, onClick }) => {
  const filter = data.find(filter => filter.code === code) || {};
  return (
    <List>
      <ListItem button onClick={onClick}>
        <ListItemText primary={filter.name} />
      </ListItem>
    </List>
  );
};

export default function CustomToolPanel(props) {
  const [appliedFilters, setAppliedFilters] = useState({});
  const { data, code, api } = props;

  useEffect(() => {
    api.addEventListener("filterChanged", updateAppliedFilters);

    return () => api.removeEventListener("filterChanged", updateAppliedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAppliedFilters = () => {
    const filters = api.getFilterModel();
    setAppliedFilters(filters);
  };

  const handleClick = (filter, filterType) => {
    let filters = { ...appliedFilters };
    const filterExist = filters.hasOwnProperty(
      Object.keys(filter.filter_criteria)[0]
    );

    if (filterExist) {
      delete filters[Object.keys(filter.filter_criteria)[0]];
    } else {
      filters = { ...filters, ...filter.filter_criteria };
    }

    api.setFilterModel(filters);
  };

  const toolPanels = {
    saved_filters: RenderSavedFilters,
    category: RenderCategoryFilters,
    tag: RenderTagFilters
  };

  const ToolPanel = toolPanels[code];

  return (
    <div>
      {ToolPanel && (
        <ToolPanel
          data={data}
          code={code}
          onClick={handleClick}
          appliedFilters={appliedFilters}
        />
      )}
    </div>
  );
}
