import gql from "graphql-tag";

export default gql`
  query {
    me {
      id
      email
      nickname
      role
      name
      avatar
      emailVerified
      onboarded
      # preferences {
      #   language
      #   startUrl
      # }
      buttons {
        name
        path
        icon
      }
      account {
        name
      }
    }
  }
`;
