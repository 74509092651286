import React, { useState } from "react";
import SheetDataService from "lib/SheetDataService";
import HelperButton from "./helperButton";
import { useTestHelper } from "components/app/grid";

export default function CurrencyRenderer({
  value,
  data,
  channelData,
  typecast,
  api,
  node,
  rowIndex,
  column,
  editable,
  onTesting,
  intl
}) {
  const [isHelperVisible, setIsHelperVisible] = useState(false);
  const testTooltip = useTestHelper({
    value,
    data,
    api,
    node,
    column,
    onTesting,
    intl
  });

  const isEditable = editable && editable({ column, data });
  const currency = SheetDataService.currencyFormatter(channelData, value, 2);
  const htmlEscaped = SheetDataService.stripHtmlTags(currency);

  const handleMouseEnter = () => {
    setIsHelperVisible(true);
  };

  const handleMouseLeave = () => {
    setIsHelperVisible(false);
  };

  const handleHelperClick = () => {
    api.setFocusedCell(rowIndex, column.colId);
    api.startEditingCell({
      rowIndex: rowIndex,
      colKey: column.colId,
      keyPress: "helperBtn"
    });
  };

  return (
    <div
      className="ag-cell-container ag-cell-with-helper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span>{htmlEscaped}</span>
      <div className="cell-helpers-wrapper">
        {isHelperVisible && isEditable && (
          <HelperButton type={typecast} onClick={handleHelperClick} />
        )}
        {isHelperVisible && testTooltip}
      </div>
    </div>
  );
}
