import gql from "graphql-tag";

export default gql`
  query {
    entities {
      id
      code
      name
      icon
      position
      tooltip
    }
  }
`;
