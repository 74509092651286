import React from "react";
import Auth0Lock from "auth0-lock";
import getConfig from "../../getConfig";
import { Auth } from "../../lib";

const LOCK_DOM_ID = "auth0-login-container";

class Lock extends React.Component {
  constructor(props) {
    super(props);

    if (window.location.pathname !== "/") window.location.href = "/";

    const config = getConfig();
    this.lock = new Auth0Lock(config.auth.clientID, config.auth.domain, {
      auth: {
        responseType: "token id_token",
        sso: false
      },
      container: LOCK_DOM_ID,
      languageDictionary: {
        title: "Sheetly"
      },
      theme: {
        logo: "http://sheetly.io/assets/img/logo/sheetly-logo-15-blue.png",
        primaryColor: "#3a99d8"
      }
    });

    this.lock.on("authenticated", authResult => {
      Auth.fromLock(authResult).then(() => {
        window.location = "/";
      });
    });
  }

  componentDidMount() {
    // Avoid showing Lock when hash is parsed.
    if (!/access_token|id_token|error/.test(window.location)) {
      this.lock.show();
    }
  }

  render() {
    return (
      <div>
        <div id={LOCK_DOM_ID} />
      </div>
    );
  }
}

export default Lock;
