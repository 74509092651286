import React from "react";

const Container = ({ children }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row"
    }}
  >
    {children}
  </div>
);

const FormWrapper = ({ children }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignContent: "center",
      width: "500px",
      height: "100vh"
    }}
  >
    <div
      style={{
        textAlign: "center",
        paddingTop: "80px"
      }}
    >
      <img
        src="http://sheetly.io/assets/img/logo/sheetly-logo-20-blue.png"
        alt="logo"
      />
    </div>
    {children}
    <div
      style={{
        textAlign: "center",
        paddingBottom: "30px"
      }}
    >
      <a
        href="http://sheetly.io/terms-conditions.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of service Conditions
      </a>
      <a
        href="http://sheetly.io/privacy-policy.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policyxxxxx
      </a>
    </div>
  </div>
);

const ImageBackground = () => (
  <div
    style={{
      width: "calc(100vw - 500px)",
      backgroundImage: 'url("/images/clip-1.png")',
      backgroundColor: "#ddd",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      height: "100vh"
    }}
  />
);

export default ({ children }) => (
  <Container>
    <FormWrapper>{children}</FormWrapper>
    <ImageBackground />
  </Container>
);
