import React from "react";
import { Drawer, Dialog } from "@material-ui/core";

const getSize = size => {
  return { mini: "20vw", small: "35vw", medium: "50vw", large: "80vw" }[size];
};

export default ({ open, size = "medium", children, onClose, isDialog }) =>
  isDialog ? (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div
        style={{
          padding: "2em",
          paddingTop: "1em",
          backgroundColor: "#fafafa"
        }}
      >
        {children}
      </div>
    </Dialog>
  ) : (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div
        style={{
          width: getSize(size),
          padding: "2em",
          paddingTop: "1em",
          backgroundColor: "#fafafa"
        }}
      >
        {children}
      </div>
    </Drawer>
  );
