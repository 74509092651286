import React from "react";
import InspectorBarTeleport from "components/app/Workbench/InspectorBarTeleport";

const getSize = size => {
  return {
    small: "25vw",
    medium: "33vw",
    large: "50vw",
    xlarge: "80vw",
    full: "100vw"
  }[size];
};

export default ({ open, size = "small", children }) => (
  <InspectorBarTeleport.Source>
    <div
      style={{
        display: open ? "block" : "none",
        width: getSize(size),
        height: "100%",
        marginLeft: "1em",
        paddingLeft: "1em",
        borderLeft: "1px solid #ddd"
      }}
    >
      {children}
    </div>
  </InspectorBarTeleport.Source>
);
