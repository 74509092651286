import React, { useState } from "react";
import { toast } from "react-toastify";
import withData from "lib/withData";
import { Account, Plan } from "models";
import { Registry } from "lib";
import { Field } from "lib/Form";
import {
  Card,
  // CardActionArea,
  CardContent,
  CardActions,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  // FormHelperText,
  // FormLabel,
  InputAdornment
} from "@material-ui/core";
import { Facebook } from "react-content-loader";
import TextField from "@material-ui/core/TextField";
import dayjs from "dayjs";
import {
  H5,
  P,
  Button,
  Panel,
  Grid,
  Icon,
  Preview,
  // Avatar,
  // Colours,
  Modal
} from "components/ui";
import { SettingsLayout, ResourceForm, Link } from "components/app";

const Loading = () => (
  <SettingsLayout>
    <Facebook />
  </SettingsLayout>
);

const STATES = [
  {
    label: "Alabama",
    value: "AL"
  },
  {
    label: "Alaska",
    value: "AK"
  },
  {
    label: "Arizona ",
    value: "AZ"
  },
  {
    label: "Arkansas",
    value: "AR"
  },
  {
    label: "California ",
    value: "CA"
  },
  {
    label: "Colorado ",
    value: "CO"
  },
  {
    label: "Connecticut",
    value: "CT"
  },
  {
    label: "Delaware",
    value: "DE"
  },
  {
    label: "Florida",
    value: "FL"
  },
  {
    label: "Georgia",
    value: "GA"
  },
  {
    label: "Hawaii",
    value: "HI"
  },
  {
    label: "Idaho",
    value: "ID"
  },
  {
    label: "Illinois",
    value: "IL"
  },
  {
    label: "Indiana",
    value: "IN"
  },
  {
    label: "Iowa",
    value: "IA"
  },
  {
    label: "Kansas",
    value: "KS"
  },
  {
    label: "Kentucky",
    value: "KY"
  },
  {
    label: "Louisiana",
    value: "LA"
  },
  {
    label: "Maine",
    value: "ME"
  },
  {
    label: "Maryland",
    value: "MD"
  },
  {
    label: "Massachusetts",
    value: "MA"
  },
  {
    label: "Michigan",
    value: "MI"
  },
  {
    label: "Minnesota",
    value: "MN"
  },
  {
    label: "Mississippi",
    value: "MS"
  },
  {
    label: "Missouri",
    value: "MO"
  },
  {
    label: "Montana",
    value: "MT"
  },
  {
    label: "Nebraska",
    value: "NE"
  },
  {
    label: "Nevada",
    value: "NV"
  },
  {
    label: "New Hampshire",
    value: "NH"
  },
  {
    label: "New Jersey",
    value: "NJ"
  },
  {
    label: "New Mexico",
    value: "NM"
  },
  {
    label: "New York",
    value: "NY"
  },
  {
    label: "North Carolina",
    value: "NC"
  },
  {
    label: "North Dakota",
    value: "ND"
  },
  {
    label: "Ohio",
    value: "OH"
  },
  {
    label: "Oklahoma",
    value: "OK"
  },
  {
    label: "Oregon",
    value: "OR"
  },
  {
    label: "Pennsylvania",
    value: "PA"
  },
  {
    label: "Rhode Island",
    value: "RI"
  },
  {
    label: "South Carolina",
    value: "SC"
  },
  {
    label: "South Dakota",
    value: "SD"
  },
  {
    label: "Tennessee",
    value: "TN"
  },
  {
    label: "Texas",
    value: "TX"
  },
  {
    label: "Utah",
    value: "UT"
  },
  {
    label: "Vermont",
    value: "VT"
  },
  {
    label: "Virginia ",
    value: "VA"
  },
  {
    label: "Washington",
    value: "WA"
  },
  {
    label: "West Virginia",
    value: "WV"
  },
  {
    label: "Wisconsin",
    value: "WI"
  },
  {
    label: "Wyoming",
    value: "WY"
  }
];

const getInitialProps = async () => {
  const db = Registry.get("db");
  const account = Registry.get("account");
  const client = Registry.get("client");

  const plans = await Plan(db).all();

  const changePlan = value => {
    client.callFunction("changePlan", value);
    toast("account plan updated");
  };

  const update = async ({ id, values }) => {
    await Account(db).update(id, values);
    toast("account updated");
  };

  return {
    plans,
    changePlan,
    account,
    update
  };
};

const EditProfile = ({ loading, data }) => {
  const [planModalVisible, setPlanModalVisible] = useState(false);
  const [countryModalVisible, setCountryModalVisible] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [valueCountry, setValueCountry] = useState("");

  if (loading) return <Loading />;

  const { account, update, changePlan } = data;
  const isPlanTrail = account.planCode === "trial";
  const isUSA = account.jurisdiction === "USA";

  function preparePlans(rawPlans, currentPlan) {
    return rawPlans.map(plan => {
      const selected = plan.code === currentPlan;
      const bgColor = selected ? "#27b1e3" : "#fff";

      return { ...plan, selected, bgColor };
    });
  }

  function handleCountryChange(event) {
    setValueCountry(event.target.value);
  }

  function handleOpenCountryModal() {
    setValueCountry(account.jurisdiction);
    setCountryModalVisible(true);
  }

  function updateJurisdiction() {
    account.jurisdiction = valueCountry;
    // const newJurisdiction = {
    //   $set: {
    //     jurisdiction: valueCountry
    //   }
    // };
    // update({ id: account.id, account });
    changePlan([account.id, account.planCode]);
    setCountryModalVisible(false);
  }

  function triggerCancellation() {
    setCancelModalVisible(false);
  }

  function triggerPlanChange(newPlan) {
    const message = `plan updated to ${newPlan}`;
    // const client = Stitch.defaultAppClient;
    // client.callFunction('changePlan', 'jan').then(result => {
    //  console.log(result);
    // });
    setPlanModalVisible(false);
    toast(message);
  }

  return (
    <SettingsLayout title="Account">
      <Grid.Row>
        <Grid.Col s={12}>
          <Link to="http://support.sheetly.io" target="_blank">
            <Icon size="18" line="1" glyph="brain" />
            Learn more about Account, Plans and Tax
          </Link>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col s={8}>
          <Panel>
            <H5>Business information</H5>
            <ResourceForm
              id="specialform"
              resource="account"
              initialValues={account}
              onSubmit={({ values }) => update({ id: account.id, values })}
            >
              {() => (
                <>
                  <Field name="name" />
                  <Field name="url" />
                  <Button ui="secondary" type="submit">
                    Save
                  </Button>
                </>
              )}
            </ResourceForm>
          </Panel>
          <br />
          <Panel>
            <H5>Business jurisdiction</H5>
            <TextField
              label="Region"
              defaultValue={account.jurisdiction}
              margin="normal"
              fullWidth
              InputProps={{
                readOnly: true
              }}
            />
            <Button ui="secondary" onClick={() => handleOpenCountryModal()}>
              Change
            </Button>
          </Panel>
          <br />
          <Panel>
            <H5>Tax/VAT information</H5>
            <ResourceForm
              resource="account"
              initialValues={account}
              onSubmit={({ values }) => update({ id: account.id, values })}
            >
              {() => (
                <>
                  {isUSA ? (
                    <div>
                      <Field input="select" name="state" options={STATES} />
                      <Field name="zip" />
                    </div>
                  ) : (
                    <div>
                      <Field label="EU VAT or local Tax Number" name="taxId" />
                    </div>
                  )}
                  <Button ui="secondary" type="submit">
                    Save
                  </Button>
                </>
              )}
            </ResourceForm>
          </Panel>
        </Grid.Col>

        <Grid.Col s={4}>
          {/*
          <Panel>
            <div style={{ textAlign: 'center' }}>
              <Avatar image="https://placehold.it/140x140" size={72} />
              <br />
                  <Button ui="secondary" type="submit">
                    Change your logo
                  </Button>
            </div>
          </Panel>
          <br />
          */}
          <Panel>
            <H5>Plan details</H5>
            <Preview label="Plan" value={account.planName} />
            {isPlanTrail ? (
              <div>
                <Preview
                  label="Start date"
                  value={dayjs(account.trialStart).format("DD MMMM, YYYY")}
                />
                <Preview
                  label="End date"
                  value={dayjs(account.trialStart)
                    .add(account.trialDays, "day")
                    .format("DD MMMM, YYYY")}
                />
              </div>
            ) : (
              <div>
                <Preview
                  label="Start date"
                  value={dayjs(account.subscriptionStart).format(
                    "DD MMMM, YYYY"
                  )}
                />
                <Preview
                  label="Price"
                  value={account.price}
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                />
                <Preview label="Model" value={account.subscriptionModel} />
              </div>
            )}

            <Button ui="secondary" onClick={() => setPlanModalVisible(true)}>
              Change plan
            </Button>
          </Panel>
          <br />
          <Panel>
            <H5>Plan limits</H5>
            <Grid.Row>
              <Grid.Col s={4}>
                <Preview label="Users" value={account.usersAllowed} />
              </Grid.Col>
              <Grid.Col s={4}>
                <Preview label="SKUs" value={account.skusAllowed} />
              </Grid.Col>
              <Grid.Col s={4}>
                <Preview label="Channels" value={account.channelsAllowed} />
              </Grid.Col>
            </Grid.Row>
          </Panel>
          <br />
          <Panel>
            <H5>Cancel account</H5>
            <P>
              We would be very sad to see you leave, but in life sheet
              happens...
            </P>
            <P>
              We will not renew your subscription at the next billing circle,
              but we do not offer any pro rata refund.
            </P>
            <P>
              You are encurraged to use the export tool and possible the sync
              tool connected to your channels to backup and save data that we
              currently hold for you!
            </P>
            <Button
              ui="primary"
              color="secondary"
              onClick={() => setCancelModalVisible(true)}
            >
              Cancel
            </Button>
          </Panel>
        </Grid.Col>
      </Grid.Row>

      <Modal open={planModalVisible} onClose={() => setPlanModalVisible(false)}>
        <H5>Change plan</H5>
        <Link to="http://sheetly.io/price.html" target="_blank">
          <Icon
            size="18"
            line="1"
            glyph="coins"
            style={{ marginRight: "10px" }}
          />
          Learn more Plans and features
        </Link>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem ipsa
          dolore ipsum in animi quibusdam, accusamus deserunt hic error iure
          similique maxime non ratione exercitationem adipisci enim reiciendis
          veritatis quisquam?
        </p>
        <Grid.Row>
          <Grid.Col s={12}>
            {!loading && (
              <Grid.Row>
                {preparePlans(data.plans, account.planCode).map(plan => (
                  <Grid.Col key={plan.id} s={4}>
                    <Card
                      style={{
                        textAlign: "center",
                        backgroundColor: plan.bgColor
                      }}
                    >
                      <CardContent style={{ height: 80 }}>
                        <Icon size="72" line="1" glyph="banana" />
                      </CardContent>
                      <CardContent style={{ height: 220 }}>
                        <H5>{plan.name}</H5>
                        <H5>$ {plan.mrr}</H5>
                        <P>{plan.desc}</P>
                        <P>{plan.users}</P>
                        <P>{plan.skus}</P>
                        <P>{plan.channels}</P>
                      </CardContent>
                      {plan.selected ? (
                        <P>Active plan</P>
                      ) : (
                        <Button
                          ui="secondary"
                          onClick={() => triggerPlanChange(plan.code)}
                        >
                          Select
                        </Button>
                      )}
                      <CardActions />
                    </Card>
                  </Grid.Col>
                ))}
              </Grid.Row>
            )}
          </Grid.Col>
        </Grid.Row>
      </Modal>

      <Modal
        open={countryModalVisible}
        onClose={() => setCountryModalVisible(false)}
      >
        <H5>Change jurisdiction</H5>
        <P>
          We need to know the jurisdiction of your business in order to
          determind if we need to apply sales tax to your invoice and how we
          should police and protect your personal data.
        </P>
        <FormControl component="fieldset">
          <RadioGroup
            name="jurisdiction"
            value={valueCountry}
            onChange={handleCountryChange}
          >
            <FormControlLabel
              value="USA"
              control={<Radio color="primary" />}
              label="USA (sales tax purposes)"
            />
            <FormControlLabel
              value="EU"
              control={<Radio color="primary" />}
              label="EU (GDPR purposes)"
            />
            <FormControlLabel
              value="other"
              control={<Radio color="primary" />}
              label="Other"
            />
          </RadioGroup>
        </FormControl>
        <div>
          <Button ui="secondary" onClick={() => updateJurisdiction()}>
            Save
          </Button>
        </div>
      </Modal>

      <Modal
        open={cancelModalVisible}
        onClose={() => setCancelModalVisible(false)}
      >
        <H5>Cancel the account</H5>
        <P>Are you really sure you want to leave us?.</P>
        <div>
          <Button ui="secondary" onClick={() => triggerCancellation()}>
            CANCEL
          </Button>
        </div>
      </Modal>
    </SettingsLayout>
  );
};

export default withData(EditProfile, getInitialProps);
