import React from "react";
import { AppBar } from "@material-ui/core";
import { H4 } from "components/ui";

export default ({ title, children }) => (
  <AppBar
    position="static"
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "0.25em",
      paddingBottom: "0.25em",
      backgroundColor: "#fff",
      boxShadow: "none"
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingLeft: "0.85em",
        paddingRight: "0.25em"
      }}
    >
      <img
        src="/images/misc/sheetly-logo-bars.png"
        alt="logo"
        style={{
          height: "32px",
          marginRight: "1.0em"
        }}
      />

      <H4>{title}</H4>
    </div>

    <div>{children}</div>
  </AppBar>
);
