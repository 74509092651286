import React from "react";
import {
  Stitch,
  GoogleRedirectCredential,
  FacebookRedirectCredential,
  UserPasswordCredential,
  RemoteMongoClient
} from "mongodb-stitch-browser-sdk";
import { Registry } from "lib";
import StitchContext from "./StitchContext";

const client = Stitch.initializeDefaultAppClient("sheetlystarbucks-uxvco");
const db = client.getServiceClient(
  RemoteMongoClient.factory,
  "SheetlyStarbucksStitch"
);

Registry.add("db", db);
Registry.add("client", client);

export default ({ children }) => {
  const loginGoogle = async () =>
    client.auth.loginWithRedirect(new GoogleRedirectCredential());

  const loginFacebook = async () =>
    client.auth.loginWithRedirect(new FacebookRedirectCredential());

  const loginEmail = async () =>
    client.auth.loginWithRedirect(new UserPasswordCredential());

  const logout = async () => {
    await client.auth.logout();
    window.location = "/";
    // window.location.reload();
  };

  if (client.auth.hasRedirectResult())
    client.auth.handleRedirectResult().then(() => window.location.reload());

  const loggedIn = client.auth.isLoggedIn;

  // const user = User.findByStitch(client.auth.user.id);
  // Registry.add('user', user);

  const auth = {
    client,
    db,
    loggedIn,
    stitchUser: loggedIn ? client.auth.user : {},
    // user: loggedIn ? user : {},
    // account: loggedIn ? getAccount(client.auth.user.id) : {},
    loginGoogle,
    loginFacebook,
    loginEmail,
    logout,
    buttons: []
  };

  return (
    <StitchContext.Provider value={auth}>{children}</StitchContext.Provider>
  );
};
