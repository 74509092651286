import { ObjectId } from "bson";
//import { getCurrentUser } from 'stitch';
import { Registry, serialize } from "lib";
import { Auth } from "lib";

export default {
  all: async () => {
    const db = Registry.get("db");
    //const user = await getCurrentUser();
    const user = await Auth.getCurrentUser();
    const ownerId = user ? user.ownerId : null;
    return serialize(
      await db
        .db("sheetly_seed")
        .collection("cards")
        .find({ ownerId }, { sort: { position: 1 } })
        .asArray()
    );
  },
  find: async id => {
    const db = Registry.get("db");
    return serialize(
      await db
        .db("sheetly_seed")
        .collection("cards")
        .findOne({ _id: new ObjectId(id) })
    );
  },
  create: async values => {
    const db = Registry.get("db");
    await db
      .db("sheetly_seed")
      .collection("cards")
      .insertOne(values);
  },
  update: async (id, valueWithIcon) => {
    const db = Registry.get("db");
    await db
      .db("sheetly_seed")
      .collection("cards")
      .updateOne({ _id: new ObjectId(id) }, valueWithIcon);
  },
  destroy: async id => {
    const db = Registry.get("db");
    await db
      .db("sheetly_seed")
      .collection("cards")
      .deleteOne({ _id: new ObjectId(id) });
  }
};
