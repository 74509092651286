import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { ExitToApp as LogoutIcon } from "@material-ui/icons";
import { Auth } from "lib";
//import { StitchContext } from "stitch";

export default () => {
  const [user, setUser] = useState({});

  useEffect(() => {
    const userData = Auth.getProfile();
    if (userData) {
      setUser(userData);
    }
  }, []);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link to="/settings" style={{ width: "32px", height: "32px" }}>
          <img
            src={user.picture}
            alt={user.name}
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "50%",
              marginRight: "0.5em"
            }}
          />
        </Link>

        <IconButton
          color="inherit"
          aria-label="Logout"
          onClick={() => new Auth().logout()}
        >
          <LogoutIcon />
        </IconButton>
      </div>
    </>
  );
};
