import { ObjectId } from "bson";
import { Registry, serialize } from "lib";

export default {
  all: async () => {
    const db = Registry.get("db");
    const user = Registry.get("user");
    const ownerId = user ? user.ownerId : null;
    return serialize(
      await db
        .db("sheetly_seed")
        .collection("filters")
        .find({ ownerId })
        .asArray()
    );
  },
  find: async id => {
    const db = Registry.get("db");
    return serialize(
      await db
        .db("sheetly_seed")
        .collection("filters")
        .findOne({ _id: new ObjectId(id) })
    );
  },
  create: async values => {
    const db = Registry.get("db");
    await db
      .db("sheetly_seed")
      .collection("filters")
      .insertOne(values);
  },
  update: async (id, values) => {
    const db = Registry.get("db");
    await db
      .db("sheetly_seed")
      .collection("filters")
      .updateOne({ _id: new ObjectId(id) }, values);
  },
  destroy: async id => {
    const db = Registry.get("db");
    await db
      .db("sheetly_seed")
      .collection("filters")
      .deleteOne({ _id: new ObjectId(id) });
  }
};
