import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect
} from "react";
import DatePicker from "react-datepicker";
import { fromUnixTime, getUnixTime } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";

function DateEditor({ value, api, channelData }, ref) {
  const selectedDate = value
    ? Date.parse(fromUnixTime(value)) || new Date()
    : new Date();
  const [dateVal, setDateVal] = useState(selectedDate);
  const [editing, setEditing] = useState(true);

  useEffect(() => {
    if (!editing) {
      api.stopEditing();
    }
  }, [api, editing]);

  useImperativeHandle(ref, () => {
    const formatDate = getUnixTime(dateVal);
    return {
      getValue() {
        return formatDate;
      },

      isPopup() {
        return true;
      }
    };
  });

  const handleChange = date => {
    setDateVal(date);
    setEditing(false);
  };

  return <DatePicker selected={dateVal} inline onChange={handleChange} />;
}

export default forwardRef(DateEditor);
