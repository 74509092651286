import React from "react";
import { Query } from "components/app";
import { LinkButton, Toolbar, Panel, H5, P } from "components/ui";
import { toolbarbuttons } from "graphql/queries";
import { SettingsLayout } from "../../components/app";

export default () => (
  <SettingsLayout title="Toolbar Buttons">
    <H5>Toolbar Buttons here</H5>
    <P>Introduction to toolbar buttons</P>
    <Toolbar>
      <LinkButton ui="primary" to="/dashboard">
        New Button
      </LinkButton>
    </Toolbar>
    <Panel>
      <table size="small">
        <thead>
          <tr>
            <th>Icon</th>
            <th>Name</th>
            <th>Position</th>
            <th>Path</th>
          </tr>
        </thead>
        <tbody>
          <Query
            query={toolbarbuttons}
            loading={
              <tr>
                <td colSpan="3">Loading</td>
              </tr>
            }
          >
            {({ toolbarbuttons }) =>
              toolbarbuttons.map(toolbarbutton => (
                <tr key={toolbarbutton.id}>
                  <td>
                    <img
                      src={toolbarbutton.icon}
                      alt={toolbarbutton.name}
                      style={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                        marginRight: "0.5em"
                      }}
                    />
                  </td>
                  <td>{toolbarbutton.name}</td>
                  <td>{toolbarbutton.position}</td>
                  <td>{toolbarbutton.path}</td>
                </tr>
              ))
            }
          </Query>
        </tbody>
      </table>
    </Panel>
  </SettingsLayout>
);
