import React from "react";
import { injectIntl } from "react-intl";
import humanize from "humanize-string";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@material-ui/core";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import FormContext from "./FormContext";

const TextInput = ({ type = "text", setFieldValue, ...props }) => (
  <TextField label="Name" margin="normal" type={type} {...props} />
);
const TextAreaInput = ({ rowsMax = 4, setFieldValue, ...props }) => (
  <TextInput multiline rowsMax={rowsMax} {...props} />
);
const NumberInput = ({ setFieldValue, ...props }) => (
  <TextInput type="number" {...props} />
);
const EmailInput = ({ setFieldValue, ...props }) => (
  <TextInput type="email" {...props} />
);
const PasswordInput = ({ setFieldValue, ...props }) => (
  <TextInput type="password" {...props} />
);
const SelectInput = ({ options, label, setFieldValue, ...props }) => {
  const { name, id } = props;

  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel>{label}</InputLabel>

      <Select
        inputProps={{
          name,
          id
        }}
        {...props}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const DateInput = ({ label, name, value, setFieldValue }) => (
  <FormControl margin="normal" fullWidth>
    <div className="date-picker-control">
      <label htmlFor={label}>{label}</label>

      <DatePicker
        selected={value ? new Date(value) : null}
        dateFormat="MMMM d, yyyy"
        onChange={v => {
          const formatted = dayjs(v).format("YYYY-MM-DD");
          setFieldValue(name, formatted);
        }}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </div>
  </FormControl>
);

const CheckboxInput = ({ label, name, value, setFieldValue }) => (
  <FormControl margin="normal" fullWidth>
    <div className="date-picker-control">
      <label htmlFor={name}>{label}</label>
      <input
        type="checkbox"
        name={name}
        checked={value}
        onChange={e => setFieldValue(name, !!e.target.checked)}
      />
    </div>
  </FormControl>
);

const INPUTS = {
  text: TextInput,
  textarea: TextAreaInput,
  select: SelectInput,
  email: EmailInput,
  password: PasswordInput,
  number: NumberInput,
  date: DateInput,
  checkbox: CheckboxInput
};

const Field = ({
  name,
  label,
  placeholder,
  input = "text",
  intl,
  ...props
}) => (
  <FormContext.Consumer>
    {({
      resource,
      autosave,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      submitForm,
      setFieldValue
    }) => {
      const Component =
        typeof input === "function" ? input : INPUTS[input] || TextInput;

      return (
        <div>
          <Component
            id={`field_${name}`}
            label={
              label ||
              intl.formatMessage({
                id: `forms.${resource}.fields.${name}`,
                defaultMessage: humanize(name)
              })
            }
            placeholder={
              placeholder ||
              intl.formatMessage({
                id: `forms.${resource}.placeholders.${name}`,
                defaultMessage: " "
              })
            }
            name={name}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            onBlur={e => {
              handleBlur(e);

              if (autosave) {
                setTimeout(() => {
                  submitForm();
                }, 1000);
              }
            }}
            value={values[name] || ""}
            error={errors[name]}
            style={{ width: "100%" }}
            {...props}
          />
          {errors[name] && (
            <small>{errors[name] && touched[name] && errors[name]}</small>
          )}
        </div>
      );
    }}
  </FormContext.Consumer>
);

export default injectIntl(Field);
