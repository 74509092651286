const isObject = obj =>
  Object.prototype.toString.call(obj) === "[object Object]";

export default thing => {
  if (isObject(thing)) return { ...thing, id: thing._id.toString() };

  if (Array.isArray(thing))
    return thing.map(i => ({ ...i, id: i._id.toString() }));

  return thing;
};
