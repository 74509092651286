import React from "react";
import { Formik } from "formik";
import FormContext from "./FormContext";

export default ({
  resource = "default",
  autosave = false,
  children,
  onSubmit,
  ...props
}) => (
  <Formik
    onSubmit={(values, { setSubmitting }) => {
      setSubmitting(false);
      onSubmit(values);
    }}
    {...props}
  >
    {({ handleSubmit, ...rest }) => (
      <form onSubmit={handleSubmit}>
        <FormContext.Provider value={{ resource, autosave, ...rest }}>
          {children(rest)}
        </FormContext.Provider>
      </form>
    )}
  </Formik>
);
