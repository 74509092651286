import React, { useState } from "react";
import { SheetDataService } from "lib";
import { useTestHelper } from "components/app/grid";

export default function BooleanRenderer({
  value,
  api,
  column,
  data,
  node,
  editable,
  onTesting,
  intl
}) {
  const [isHelperVisible, setIsHelperVisible] = useState(false);
  const testTooltip = useTestHelper({
    value,
    data,
    api,
    node,
    column,
    onTesting,
    intl
  });

  const isEditable = editable && editable({ column, data });
  const valueCleaned = SheetDataService.booleanCleaner(value);
  const htmlEscaped = SheetDataService.stripHtmlTags(
    valueCleaned !== null ? valueCleaned : value
  );

  const handleMouseEnter = () => {
    setIsHelperVisible(true);
  };

  const handleMouseLeave = () => {
    setIsHelperVisible(false);
  };

  const checkedHandler = event => {
    isEditable && node.setDataValue(column.colId, event.target.checked);
  };

  return (
    <div
      className="ag-cell-container ag-cell-with-helper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!value || typeof valueCleaned === "boolean" ? (
        <input
          type="checkbox"
          checked={!!valueCleaned}
          onChange={checkedHandler}
          disabled={!isEditable}
        />
      ) : (
        htmlEscaped
      )}
      <div className="cell-helpers-wrapper">
        {isHelperVisible && testTooltip}
      </div>
    </div>
  );
}
