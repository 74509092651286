import React, { useState } from "react";
import { Button, Toolbar, H5, P, Icon, Panel, Modal } from "components/ui";
import { SettingsLayout, InspectorBar } from "components/app";
import IconButton from "@material-ui/core/IconButton";
import { TwoWayQuerybuilder } from "react-two-way-querybuilder";
import withData from "lib/withData";
import { Filter } from "models";
import ContentLoader from "react-content-loader";

// Should be moved to a separate folder for reuse across screens
const SettingsListLoader = () => (
  <ContentLoader
    height={300}
    width={400}
    speed={2}
    primaryColor="#c0c0c0"
    secondaryColor="#ecebeb"
  >
    <rect x="0" y="15" rx="1" ry="1" width="300" height="100" />
    <rect x="10" y="2" rx="1" ry="1" width="100" height="5" />
    <circle cx="4" cy="4" r="4" />
  </ContentLoader>
);

const Loading = () => (
  <SettingsLayout>
    <SettingsListLoader />
  </SettingsLayout>
);

const getInitialProps = async ({ db, user }) => {
  const filters = await Filter(db).all(user.ownerId);

  return {
    filters
  };
};

const queryFields = [
  {
    name: "firstName",
    operators: "all",
    label: "First Name",
    input: { type: "text" }
  },
  {
    name: "lastName",
    operators: "all",
    label: "Last Name",
    input: { type: "text" }
  },
  { name: "age", operators: "all", label: "Age", input: { type: "text" } },
  {
    name: "birthDate",
    operators: "all",
    label: "Birth date",
    input: { type: "text" }
  }
];

const Index = ({ loading, data }) => {
  const [inspector, setInspector] = useState(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [current] = useState(null);

  if (loading) return <Loading />;

  function handleChange() {
    // console.log('query', event.query);
  }

  return (
    <SettingsLayout title="Filters">
      <Icon size="24" line="1" glyph="filter" />
      <H5>Filters here</H5>
      <P>Introduction to filters</P>

      <Toolbar>
        <Button
          ui="secondary"
          size="small"
          onClick={() => setInspector(!inspector)}
        >
          Inspector
        </Button>
      </Toolbar>

      <Panel>
        <table size="small">
          <thead>
            <tr>
              <th>Entity</th>
              <th>Name</th>
              <th>Desc</th>
              <th>Owner</th>
            </tr>
          </thead>
          <tbody>
            {loading && <p>Loading...</p>}

            {!loading &&
              data.filters.map(filter => (
                <tr key={filter.id}>
                  <td>{filter.entityGroup}</td>
                  <td>{filter.name}</td>
                  <td>{filter.desc}</td>
                  <td>{filter.owner}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Panel>
      <InspectorBar open={inspector} size="small">
        <H5>Inspector</H5>
        <p>Detail view of ...</p>
        <Toolbar>
          <Button
            ui="secondary"
            size="small"
            onClick={() => setCreateModalVisible(true)}
          >
            Edit
          </Button>
          <Button
            ui="secondary"
            color="secondary"
            size="small"
            onClick={() => setInspector(false)}
          >
            Delete
          </Button>
        </Toolbar>
        <Panel>{current && <p>Detail view of ...</p>}</Panel>
      </InspectorBar>
      {/* The create form */}
      <Modal
        open={createModalVisible}
        onClose={() => setCreateModalVisible(false)}
      >
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <H5>Create Filter</H5>
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => setCreateModalVisible(false)}
            >
              <Icon size="24" line="1" glyph="bigx" />
            </IconButton>
          </div>
          <br />
          <TwoWayQuerybuilder fields={queryFields} onChange={handleChange} />
        </div>
      </Modal>
    </SettingsLayout>
  );
};

export default withData(Index, getInitialProps);
