import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function RichTextEditor({ initValue, onChange }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(initValue || ""))
      )
    );
  }, [initValue]);

  const handleEditorStateChange = state => {
    const stateToHtml = stateToHTML(editorState.getCurrentContent());
    setEditorState(state);
    onChange(stateToHtml);
  };

  return (
    <Editor
      editorState={editorState}
      //defaultEditorState={value}
      onEditorStateChange={handleEditorStateChange}
    />
  );
}
