import gql from "graphql-tag";

export default gql`
  query {
    toolbarbuttons {
      name
      icon
      path
    }
  }
`;
