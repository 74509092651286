export const WS_BASE = "wss://ws.bitstamp.net"; // replace with original webSocket

export const IMAGES_PATH = {
  thumbnail: "/images/thumbnail",
  original: "/images/original"
};

export const CHANNELS = {
  core: "core"
};

export const TYPECASTS = {
  select: "select",
  date: "date",
  boolean: "boolean",
  image: "image",
  text: "text",
  html: "html",
  currency: "currency",
  integer: "integer",
  decimal: "decimal",
  url: "url",
  email: "email"
};

export const DISPLAYS = {
  select: "select",
  boolean: "boolean",
  date: "date",
  image: "image",
  gallery: "gallery",
  currency: "currency",
  text: "text",
  html: "html",
  overall_health: "overall_health",
  hierarchy: "hierarchy"
};

export const FILTER_FROM_FILTER_TYPE = {
  decimal: "agNumberColumnFilter",
  integer: "agNumberColumnFilter",
  text: "agTextColumnFilter",
  date: "agDateColumnFilter",
  select: "agTextColumnFilter"
};

export const TYPE_FROM_TYPECAST = {
  date: "numericColumn",
  currency: "numericColumn",
  integer: "numericColumn"
};

export const RENDERER_FROM_DISPLAY = {
  boolean: "booleanRenderer",
  image: "imageRenderer",
  gallery: "imageRenderer",
  currency: "currencyRenderer",
  text: "textRenderer",
  html: "htmlRenderer",
  overall_health: "overallHealthRenderer"
};

export const EDITOR_FROM_TYPECAST = {
  select: "agRichSelectCellEditor",
  date: "dateEditor",
  integer: "calculatorEditor",
  currency: "calculatorEditor"
};

export const RELATION_CONTEXT = {
  lookups: "lookups",
  sys_sets: "sys_sets"
};

export const TESTS = {
  notNull: "notnull",
  unique: "unique",
  blacklist: "blacklist",
  required: "required",
  range: "range",
  period: "period",
  compact: "compact",
  words: "words",
  characters: "characters",
  whitelist: "whitelist"
};

export const FILTER_OPTIONS = {
  clearFilter: "clear_filter",
  editFilter: "edit_filter",
  saveFilter: "save_filter"
};

export const VIEW_OPTIONS = {
  editView: "edit_view",
  copyView: "copy_view",
  saveView: "save_view"
};

export const SELECTION_OPTIONS = {
  filterToSelect: "filter_to_select",
  clearSelected: "clear_selected",
  selectAll: "select_all",
  invert: "invert"
};

export const TOOLBAR_FILTER_TYPE = {
  tempFilter: "tempFilter",
  categoryFilter: "categoryFilter",
  tagFilter: "tagFilter"
};
