import React from "react";
import { Query } from "react-apollo";

const QueryOverride = ({ loading, children, ...props }) => (
  <Query {...props}>
    {({ data, loading, error }) => {
      if (error) {
        return <p>An error occured. </p>;
      }

      if (loading) {
        return loading;
      }

      return children(data);
    }}
  </Query>
);

QueryOverride.defaultProps = {
  loading: () => <span>Loading...</span>,
  fetchPolicy: "network-only"
};

export default QueryOverride;
