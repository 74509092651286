import React from "react";
import { IntlProvider, addLocaleData } from "react-intl";
import enLocale from "react-intl/locale-data/en";
import bgLocale from "react-intl/locale-data/bg";
import messages from "../translations.json";

addLocaleData([...enLocale, ...bgLocale]);

const I18nProvider = ({ locale = "en", children }) => (
  <IntlProvider locale={locale} messages={messages[locale]}>
    {children}
  </IntlProvider>
);

export default I18nProvider;
