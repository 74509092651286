import React from "react";
import { toast } from "react-toastify";
import { Toolbar, LinkButton } from "components/ui";
import { AuthenticatedLayout, ResourceForm } from "components/app";
import { Field, Submit } from "lib/Form";
import withData from "lib/withData";
import { Book } from "models";

const Loading = () => (
  <AuthenticatedLayout title="Loading book details...">
    <p>Loading...</p>
  </AuthenticatedLayout>
);

const getInitialProps = async ({ db, history }) => {
  const create = async ({ values }) => {
    await Book(db).create(values);

    history.push("/books");
    toast("Book was created");
  };

  return {
    create
  };
};

const Create = ({ loading, data }) => {
  if (loading) return <Loading />;

  const { create } = data;

  return (
    <AuthenticatedLayout title="New book">
      <Toolbar>
        <LinkButton ui="primary" to="/books">
          Back to list
        </LinkButton>
      </Toolbar>

      <ResourceForm
        resource="book"
        initialValues={{ title: "", author: "" }}
        onSubmit={({ values }) => create({ values })}
      >
        {() => (
          <>
            <Field name="title" />
            <Field name="author" />

            <Submit>Save</Submit>
          </>
        )}
      </ResourceForm>
    </AuthenticatedLayout>
  );
};

export default withData(Create, getInitialProps);
