import React from "react";
// import React, { useState } from 'react';
import { Button, Toolbar, H5, P, Grid, Icon, Avatar } from "components/ui";
import withData from "lib/withData";
import { User } from "models";
import { Card, CardActionArea, CardContent } from "@material-ui/core";
import { SettingsLayout } from "../../components/app";

const getInitialProps = async ({ user }) => {
  const users = User.all(user.ownerId);

  return {
    users
  };
};

// const [inspector, setInspector] = useState(false);
// const [current] = useState(null);

const Index = ({ loading, data }) => (
  <SettingsLayout title="Entities">
    <Icon size="24" line="1" glyph="user" />
    <H5>Users here</H5>
    <P>Introduction to users</P>

    <Toolbar>
      <Button
        ui="secondary"
        size="small"
        // onClick={() => setInspector(!inspector)}
      >
        Inspector
      </Button>
    </Toolbar>

    {loading && <p>Loading...</p>}

    {!loading && (
      <Grid.Row>
        {data.users.map(user => (
          <Grid.Col s={2}>
            <Card key={user.id}>
              <CardActionArea style={{ textAlign: "center" }}>
                <CardContent style={{ height: 100 }}>
                  <Avatar image={user.avatar} size={72} />
                </CardContent>
                <CardContent style={{ height: 150 }}>
                  <H5>{user.name}</H5>
                  <p>{user.role}</p>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid.Col>
        ))}
      </Grid.Row>
    )}
    {/*
      <InspectorBar open={inspector} size="small">
      <H5>Inspector</H5>
      <p>Detail view of ...</p>
      <Toolbar>
        <Button ui="secondary" size="small" onClick={() => setInspector(false)}>
          Edit
        </Button>
        <Button
          ui="secondary"
          color="secondary"
          size="small"
          onClick={() => setInspector(false)}
        >
          Delete
        </Button>
      </Toolbar>
      <Panel>{current && <p>Detail view of ...</p>}</Panel>
    </InspectorBar>
    */}
  </SettingsLayout>
);

export default withData(Index, getInitialProps);
