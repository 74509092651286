import React, { useState } from "react";
import { H5, Icon, Button, Modal } from "components/ui";
import { SettingsLayout, InspectorBar } from "components/app";

const ICONS = [
  { glyph: "banana", stroke: "orange", line: 1 },
  { glyph: "apple", stroke: "violet", line: 3 }
];

export default () => {
  const [modal, setModal] = useState(false);
  const [inspector, setInspector] = useState(false);

  return (
    <SettingsLayout title="Billing">
      <H5>Billing here</H5>

      {ICONS.map(icon => (
        <Icon
          key={icon.glyph}
          size="32"
          glyph={icon.glyph}
          line={icon.line}
          stroke={icon.stroke}
          fill={icon.fill}
        />
      ))}

      <Icon size="48" line="1" stroke="white" glyph="panda" />

      <img
        src="https://placehold.it/1920x540"
        alt="Example"
        style={{ maxWidth: "100%" }}
      />

      <br />

      <Button ui="primary" onClick={() => setModal(true)}>
        Toggle Modal
      </Button>

      <br />
      <br />

      <Button ui="primary" onClick={() => setInspector(!inspector)}>
        Toggle Inspector
      </Button>

      <Modal open={modal} onClose={() => setModal(false)}>
        <strong>Test</strong>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem ipsa
          dolore ipsum in animi quibusdam, accusamus deserunt hic error iure
          similique maxime non ratione exercitationem adipisci enim reiciendis
          veritatis quisquam?
        </p>
      </Modal>

      <InspectorBar open={inspector} size="small">
        <strong>Test</strong>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem ipsa
          dolore ipsum in animi quibusdam, accusamus deserunt hic error iure
          similique maxime non ratione exercitationem adipisci enim reiciendis
          veritatis quisquam?
        </p>
        <Button ui="primary" onClick={() => setInspector(false)}>
          Close
        </Button>
      </InspectorBar>
    </SettingsLayout>
  );
};
