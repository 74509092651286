import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { GuestLayout } from "../components/app";
import Login from "../screens/login";

export default () => (
  <Router>
    <GuestLayout>
      <Route exact path="/*" component={Login} />
    </GuestLayout>
  </Router>
);
