import { ObjectId } from "bson";
import { Registry, serialize } from "lib";

export default {
  all: async () => {
    const db = Registry.get("db");
    return serialize(
      await db
        .db("sheetly_seed")
        .collection("books")
        .find({})
        .asArray()
    );
  },
  find: async id => {
    const db = Registry.get("db");
    return serialize(
      await db
        .db("sheetly_seed")
        .collection("books")
        .findOne({ _id: new ObjectId(id) })
    );
  },
  create: async values => {
    const db = Registry.get("db");
    await db
      .db("sheetly_seed")
      .collection("books")
      .insertOne(values);
  },
  update: async (id, values) => {
    const db = Registry.get("db");
    await db
      .db("sheetly_seed")
      .collection("books")
      .updateOne({ _id: new ObjectId(id) }, values);
  },
  destroy: async id => {
    const db = Registry.get("db");
    await db
      .db("sheetly_seed")
      .collection("books")
      .deleteOne({ _id: new ObjectId(id) });
  }
};
