import gql from "graphql-tag";

export default gql`
  query {
    dashcards {
      id
      name
      criticalLevel
      warningLevel
      icon
      position
      entityCode
      filterCode
      bgClass
      value
    }
  }
`;
