import React from "react";
import { Link } from "react-router-dom";
import { A } from "components/ui";

export default ({ to, ...props }) =>
  to.indexOf("http") !== -1 ? (
    <A href={to} {...props} />
  ) : (
    <Link to={to} {...props} />
  );
