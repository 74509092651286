import React from "react";
import { Typography } from "@material-ui/core";

export default ({ level = 1, ...props }) => (
  <Typography
    variant={`h${level}`}
    component={`h${level}`}
    gutterBottom
    {...props}
  />
);
