import { createMuiTheme } from "@material-ui/core/styles";
// import lightBlue from '@material-ui/core/colors/indigo';
// import lime from '@material-ui/core/colors/pink';

export default createMuiTheme({
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 12
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#27b1e3"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: "#E7495D"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    }
  }
});
