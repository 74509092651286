import { createContext } from "react";
import { WS_BASE } from "./constants";

export const WebSocketContext = createContext(null);

export default function webSocket() {
  let ws;
  let data;

  if (!ws) {
    ws = new WebSocket(WS_BASE);

    // websocket onopen event listener
    ws.onopen = () => {
      console.log("connected websocket main component");
    };

    ws.onmessage = event => {
      data = JSON.parse(event.data);
    };

    // websocket onclose event listener
    ws.onclose = e => {
      console.log(
        `Socket is closed. Reconnect will be attempted in 1 second.`,
        e.reason
      );
      // reconnect
      setTimeout(() => {
        webSocket(WS_BASE);
      }, 1000);
    };

    // websocket onerror event listener
    ws.onerror = err => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );

      ws.close();
    };
  }

  const sendData = data => {
    if (ws) {
      ws.send(JSON.stringify(data));
    }
  };

  return {
    socket: ws,
    sendData,
    data
  };
}
