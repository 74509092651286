import React from "react";

const Row = ({ children }) => <div className="row">{children}</div>;
const Col = ({ s = 12, children }) => (
  <div className={`col-${s}`}>{children}</div>
);

export default {
  Row,
  Col
};
