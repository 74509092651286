export const user = {
  id: -88888,
  organization_id: -99999,
  onboarded: true,
  nickname: "Jefe",
  email: "jan@sheetly.io",
  language: "US-en",
  start_url: null,
  description: "The sample user",
  avatar: "/cooldude.png",
  active: true,
  role_id: -77777,
  fullname: "Jan Andersen",
  bookmarks: [
    {
      name: "my products",
      url: "https://sheetly.dev.sheetly.app/products?view=1&channel=core"
    },
    {
      name: "my attributes",
      url: "https://sheetly.dev.sheetly.app/attributes"
    }
  ]
};

export const organization = {
  id: -99999,
  name: "Sheetly Inc",
  vanity_url: "sheetly",
  desc: "The owner of all seed metadata docs",
  striped: "cus_HvfVQiWJNiDAIq",
  trial_start: "2020-09-01",
  trial_days: 14,
  active: true,
  subscription_id: "cus_HvfVQiWJNiDAIq",
  plan: "Special",
  users_allowed: 99,
  cancellation_date: null,
  delinquent: false,
  price: 0,
  tax_id: "",
  taxable: false,
  billing_email: "jan@sheetly.io",
  billing_address: {
    street: "701 Brazos St",
    zipcode: "78701",
    city: "Austin",
    state: "Texas",
    country: "USA"
  }
};
