import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { Button, Toolbar, H5, P, Panel, Icon } from "components/ui";
import withData from "lib/withData";
import { Attribute } from "models";
import {
  SettingsLayout,
  CreateButton,
  InspectorBar
} from "../../components/app";

const columns = [
  {
    name: "id",
    label: "Id",
    options: {
      filter: false,
      display: "excluded"
    }
  },
  {
    name: "code",
    label: "Code",
    options: {
      filter: false,
      display: "excluded"
    }
  },
  {
    name: "shortLabel",
    label: "Name",
    options: {
      filter: false,
      customBodyRender: value => {
        return <Icon size="18" line="1" glyph={value} />;
      }
    }
  },
  {
    name: "desc",
    label: "Description",
    options: {
      filter: false
    }
  },
  {
    name: "entityGroup",
    Label: "Entity",
    options: {
      filter: true
    }
  },
  {
    name: "typecast",
    label: "Type",
    options: {
      filter: true
    }
  },
  {
    name: "attributeGroup",
    label: "Group",
    options: {
      filter: true
    }
  },
  {
    name: "attributeSubgroup",
    label: "SubGroup",
    options: {
      filter: true
    }
  }
];

const options = {
  filter: true,
  filterType: "dropdown",
  responsive: "stacked",
  selectableRows: "none",
  print: false,
  download: false,
  elevation: 0
};

const getInitialProps = async ({ db, user }) => {
  const attributes = await Attribute(db).all(user.ownerId);

  return {
    attributes
  };
};

const Index = ({ loading, data }) => {
  const [inspector, setInspector] = useState(false);
  const [current, setCurrent] = useState(null);
  const currentRecord = current
    ? data.attributes.find(b => b.id === current)
    : null;

  // setCurrent(data.attributes[0].id);

  return (
    <SettingsLayout title="Attributes">
      <H5>Attributes</H5>
      <P>
        Attributes are the basic building blocks that make up all the different
        kinds of data that a, lets say product, is made up of.
      </P>

      <Toolbar>
        <Button
          ui="secondary"
          size="small"
          onClick={() => setInspector(!inspector)}
        >
          Inspector
        </Button>
      </Toolbar>

      <Panel>
        {loading && <p>Loading...</p>}

        {!loading && (
          <MUIDataTable
            title="Attribute list"
            data={data.attributes}
            columns={columns}
            options={{
              ...options,
              onRowClick: row => setCurrent(row[0])
            }}
          />
        )}

        <CreateButton to="/create/Attribute" />
      </Panel>

      {currentRecord && (
        <InspectorBar open={inspector} size="small">
          <H5>Inspector</H5>
          <p>Detail view of {currentRecord.name}</p>
          <Toolbar>
            <Button
              ui="secondary"
              size="small"
              onClick={() => setInspector(false)}
            >
              Edit
            </Button>
            <Button
              ui="secondary"
              color="secondary"
              size="small"
              onClick={() => setInspector(false)}
            >
              Delete
            </Button>
          </Toolbar>
          <Panel>
            {loading && <p>Loading...</p>}
            {!loading && <pre>{JSON.stringify(currentRecord, null, 2)}</pre>}
          </Panel>
        </InspectorBar>
      )}
    </SettingsLayout>
  );
};

export default withData(Index, getInitialProps);
