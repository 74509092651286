import React from "react";
import { Query } from "components/app";
import { LinkButton, Toolbar, Panel, H5, P } from "components/ui";
import { dashcards } from "graphql/queries";
import { SettingsLayout } from "../../components/app";

export default () => (
  <SettingsLayout title="Tags">
    <H5>tags here</H5>
    <P>Introduction to tag</P>
    <Toolbar>
      <LinkButton ui="primary" to="/dashboard">
        New Tag
      </LinkButton>
    </Toolbar>
    <Panel>
      <table size="small">
        <thead>
          <tr>
            <th>Icon</th>
            <th>Name</th>
            <th>Entity</th>
            <th>Filter</th>
            <th>Critical</th>
            <th>Warning</th>
            <th>Pos</th>
          </tr>
        </thead>
        <tbody>
          <Query
            query={dashcards}
            loading={
              <tr>
                <td colSpan="3">Loading</td>
              </tr>
            }
          >
            {({ dashcards }) =>
              dashcards.map(dashcard => (
                <tr key={dashcard.id}>
                  <td>
                    <img
                      src={dashcard.icon}
                      alt={dashcard.name}
                      style={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                        marginRight: "0.5em"
                      }}
                    />
                  </td>
                  <td>{dashcard.name}</td>
                  <td>{dashcard.entityCode}</td>
                  <td>{dashcard.filterCode}</td>
                  <td>{dashcard.criticalLevel}</td>
                  <td>{dashcard.warningLevel}</td>
                  <td>{dashcard.position}</td>
                </tr>
              ))
            }
          </Query>
        </tbody>
      </table>
    </Panel>
  </SettingsLayout>
);
