import gql from "graphql-tag";

export default gql`
  query {
    tests {
      id
      code
      name
      desc
      context
      subchannelScope
      setLevel
      defaultLevel
      function
    }
  }
`;
