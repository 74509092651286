import React, { useState } from "react";
import { Card, CardActionArea, CardContent } from "@material-ui/core";
import { Button, Toolbar, H5, P, Grid, Icon, Panel } from "components/ui";
import { SettingsLayout, InspectorBar } from "components/app";
import withData from "lib/withData";
import { Channel } from "models";

const getInitialProps = async ({ db, user }) => {
  const channels = await Channel(db).all(user.ownerId);

  return {
    channels
  };
};

const Index = ({ loading, data }) => {
  const [inspector, setInspector] = useState(false);
  const [current] = useState(null);

  return (
    <SettingsLayout title="Channels">
      <Icon size="24" line="1" glyph="channel" />
      <H5>Channels here</H5>
      <P>Introduction to channels</P>

      <Toolbar>
        <Button
          ui="secondary"
          size="small"
          onClick={() => setInspector(!inspector)}
        >
          Inspector
        </Button>
      </Toolbar>

      {loading && <p>Loading...</p>}

      {!loading && (
        <Grid.Row>
          {data.channels.map(channel => (
            <Grid.Col s={3}>
              <Card>
                <CardActionArea style={{ textAlign: "center" }}>
                  <CardContent style={{ height: 100 }}>
                    <Icon size="72" line="1" glyph={channel.icon} />
                  </CardContent>
                  <CardContent style={{ height: 150 }}>
                    <H5>{channel.name}</H5>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid.Col>
          ))}
        </Grid.Row>
      )}

      <InspectorBar open={inspector} size="small">
        <H5>Inspector</H5>
        <p>Detail view of ...</p>
        <Toolbar>
          <Button
            ui="secondary"
            size="small"
            onClick={() => setInspector(false)}
          >
            Edit
          </Button>
          <Button
            ui="secondary"
            color="secondary"
            size="small"
            onClick={() => setInspector(false)}
          >
            Delete
          </Button>
        </Toolbar>
        <Panel>{current && <p>Detail view of ...</p>}</Panel>
      </InspectorBar>
    </SettingsLayout>
  );
};

export default withData(Index, getInitialProps);
