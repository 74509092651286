import gql from "graphql-tag";

export default gql`
  query($id: ID!) {
    attribute(id: $id) {
      id
      entityGroup
      code
      name
      label
      shortLabel
      desc
      defaultValue
      attributeGroup
      attributeSubgroup
      typecast
      hidden
      access
      accountVisible
      master
      testable
      constructable
      includeInSet
      origin
      filterState
      filterTyepcast
      importState
      importTypecast
      sortState
      sortTypecast
    }
  }
`;
