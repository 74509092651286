import gql from "graphql-tag";

export default gql`
  query {
    blockers {
      id
      code
      name
    }
  }
`;
