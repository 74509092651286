import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import humanize from "humanize-string";
import HelperButton from "./helperButton";
import { CustomTooltip } from "components/app/grid";
import { Typography } from "@material-ui/core";

export default function useTestHelper({ value, api, node, column, onTesting }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const failedTests = onTesting ? onTesting({ api, value, column, node }) : [];
  const open = Boolean(anchorEl);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    failedTests.length > 0 && (
      <>
        <span
          aria-owns={open ? "popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <HelperButton type="test" />
        </span>
        <CustomTooltip
          id="popover"
          anchorEl={anchorEl}
          handlePopoverClose={handlePopoverClose}
        >
          {failedTests.map((error, index) => {
            const min =
              error.params && error.params.length > 0 && error.params[0].value;
            const max =
              error.params && error.params.length > 1 && error.params[1].value;
            const words =
              error.params && error.params.length > 0 && error.params[0].value;

            return (
              <Typography key={index}>
                <FormattedMessage
                  id={`products.failedTest.${error.code}`}
                  defaultMessage={humanize(error.code)}
                  values={{
                    value,
                    min,
                    max,
                    words
                  }}
                />
              </Typography>
            );
          })}
        </CustomTooltip>
      </>
    )
  );
}
