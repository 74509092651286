import gql from "graphql-tag";

export default gql`
  query {
    roles {
      id
      code
      name
      label
      desc
      blockers
    }
  }
`;
