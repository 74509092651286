export default () => ({
  domain: process.env.REACT_APP_DOMAIN,
  auth: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    secret: process.env.REACT_APP_AUTH0_SECRET
  },
  espressoAPI: process.env.REACT_APP_ESPRESSO_API,
  latteAPI: process.env.REACT_APP_LATTE_API
});
