import gql from "graphql-tag";

export default gql`
  query {
    promos {
      id
      name
      description
      image
      featured
      launchdate
      isInProduction
      code
      readMoreUrl
    }
  }
`;
