import { ObjectId } from "bson";
import { Registry, serialize } from "lib";

export default {
  all: async () => {
    const db = Registry.get("db");
    const oid = Registry.get("user.ownerId");
    return serialize(
      await db
        .db("sheetly_seed")
        .collection("elements")
        .find({ $or: [{ ownerId: oid }, { ownerId: "SheetlyMetaSeed" }] })
        .asArray()
    );
  },
  allIcons: async () => {
    const db = Registry.get("db");
    const oid = Registry.get("user.ownerId");
    return serialize(
      await db
        .db("sheetly_seed")
        .collection("elements")
        .find({
          subGroup: "icons",
          $or: [{ ownerId: oid }, { ownerId: "SheetlyMetaSeed" }]
        })
        .asArray()
    );
  },
  find: async id => {
    const db = Registry.get("db");
    return serialize(
      await db
        .db("sheetly_seed")
        .collection("elements")
        .findOne({ _id: new ObjectId(id) })
    );
  }
};
