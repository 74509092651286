import gql from "graphql-tag";

export default gql`
  query {
    users {
      id
      email
      nickname
      role
      name
      avatar
      onboarded
      account {
        name
      }
    }
  }
`;
