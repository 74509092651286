import React from "react";
import { Link } from "react-router-dom";
import Button from "./Button";
import A from "./A";

export default ({ to, ...props }) =>
  to && to.indexOf("http") !== -1 ? (
    <Button component={A} href={to} target="_blank" {...props} />
  ) : (
    <Button component={Link} to={to} {...props} />
  );
