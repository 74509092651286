import { ObjectId } from "bson";
import { Registry, serialize } from "lib";

export default {
  find: async id => {
    const db = Registry.get("db");
    return serialize(
      await db
        .db("sheetly_seed")
        .collection("accounts")
        .findOne({ _id: new ObjectId(id) })
    );
  },
  update: async (id, values) => {
    const db = Registry.get("db");
    await db
      .db("sheetly_seed")
      .collection("accounts")
      .updateOne({ _id: new ObjectId(id) }, values);
  }
};
