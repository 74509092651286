import React from "react";
import { Card, CardActionArea, CardContent } from "@material-ui/core";
import dayjs from "dayjs";
import { H4, H5, H6, P, Colours } from "components/ui";

const getSeverityColor = (value, warningThreshold, criticalThreshold) => {
  if (warningThreshold + criticalThreshold === 0) return "transparent";
  if (parseInt(value, 10) >= criticalThreshold) return Colours.critical;
  if (parseInt(value, 10) >= warningThreshold) return Colours.warning;
  return "transparent";
};

const getBackgroundImage = icon => {
  const start = "url(/icons/";
  const url = start.concat(icon, ".svg)");
  return url;
};

function gotoURL(path) {
  window.location = path;
}

export default ({
  name,
  channel,
  value,
  entityCode,
  updatedAt,
  icon,
  warningLevel,
  criticalLevel
}) => (
  <Card>
    <CardActionArea
      onClick={() => gotoURL(`/${entityCode}?channel=${channel}&view=default`)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          backgroundColor: getSeverityColor(value, warningLevel, criticalLevel),
          backgroundImage: getBackgroundImage(icon),
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "95%",
          backgroundPositionY: "15%",
          backgroundSize: "100px 100px"
        }}
      >
        <div>
          <CardContent>
            <H4>{value}</H4>
            <H5>{name}</H5>
            <H6>{entityCode}</H6>
            <P>Last updated: {dayjs(updatedAt).format("dddd HH:mm")}</P>
          </CardContent>
        </div>
      </div>
    </CardActionArea>
  </Card>
);
