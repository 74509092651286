import React from "react";
import { toast } from "react-toastify";
import { Registry } from "lib";
import { User } from "models";
//import { getCurrentUser } from "stitch";
import { Auth } from "lib";
import { Field } from "lib/Form";
import { Facebook } from "react-content-loader";
import { GridListTile, GridList, IconButton } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {
  H5,
  P,
  Button,
  Panel,
  Grid,
  Avatar,
  Icon,
  Preview,
  Modal
} from "../../components/ui";
import { SettingsLayout, ResourceForm, Link } from "../../components/app";

const Loading = () => (
  <SettingsLayout>
    <Facebook />
  </SettingsLayout>
);

const AVATARS = [
  {
    img: "/images/misc/avatars/aloha.png"
  },
  {
    img: "/images/misc/avatars/avatar.png"
  },
  {
    img: "/images/misc/avatars/octocat.png"
  },
  {
    img: "/images/misc/avatars/avatar.png"
  },
  {
    img: "/images/misc/avatars/avatar.png"
  },
  {
    img: "/images/misc/avatars/avatar.png"
  },
  {
    img: "/images/misc/avatars/avatar.png"
  },
  {
    img: "/images/misc/avatars/avatar.png"
  },
  {
    img: "/images/misc/avatars/avatar.png"
  }
];

export default class EditProfile extends React.Component {
  state = {
    loading: true,
    me: null,
    avatarModalVisible: false
  };

  resource = () => User;

  handleUpdate = () => {
    toast("coolio");
  };

  fetch = async () => {
    //const me = await getCurrentUser();
    const me = await Auth.getCurrentUser();
    this.setState({ me, loading: false });
  };

  componentWillMount = async () => {
    await this.fetch();
  };

  update = async ({ id, values }) => {
    const user = await User.update(id, values);
    // Update the currently logged in user in the cache
    Registry.add("user", user);
    await this.fetch();
    // toast('User updated');
  };

  render() {
    const { loading, me, avatarModalVisible } = this.state;

    if (loading) return <Loading />;

    return (
      <SettingsLayout title="My Profile">
        <Grid.Row>
          <Grid.Col s={12}>
            <Link to="http://support.sheetly.io" target="_blank">
              <Icon size="18" line="1" glyph="brain" />
              Learn more about Profile and Credentials
            </Link>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col s={8}>
            <Panel>
              <H5>Personal details</H5>
              <ResourceForm
                resource="user"
                initialValues={me}
                onSubmit={({ values }) => this.update({ id: me.id, values })}
              >
                {() => (
                  <>
                    <Field name="fullName" />
                    <Field name="nickName" />
                    <Field name="desc" label="Bio" />
                    <Field
                      input="select"
                      name="language"
                      options={[
                        { value: "en", label: "English" },
                        { value: "dk", label: "Danish (Dansk)" },
                        { value: "bg", label: "Bulgarian (български)" }
                      ]}
                    />
                    <Button ui="secondary" type="submit">
                      Save
                    </Button>
                  </>
                )}
              </ResourceForm>
            </Panel>
            <br />
            <Panel>
              <H5>Bookmarks</H5>
              <div className="">
                <List dense>
                  {me.bookmarks.map(bookmark => (
                    <ListItem>
                      <ListItemIcon>
                        <Icon size="24" line="1" glyph={bookmark.icon} />
                      </ListItemIcon>
                      <ListItemText
                        primary={bookmark.name}
                        secondary={bookmark.path}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => this.handleUpdate()}
                        >
                          <Icon size="24" line="1" glyph="bin" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Panel>
          </Grid.Col>

          <Grid.Col s={4}>
            <Panel>
              <div style={{ textAlign: "center" }}>
                <Avatar image={me.avatar} size={72} />
                <br />
                <Button
                  ui="secondary"
                  onClick={() => this.setState({ avatarModalVisible: true })}
                >
                  Update Avatar
                </Button>
              </div>
            </Panel>
            <br />
            <Panel>
              <H5>User details</H5>
              <Preview label="Role" value={me.role} />
            </Panel>

            <br />

            <Panel>
              <H5>Credentials</H5>
              {me.blockPWReset ? (
                <div>
                  <P>
                    Your have signed up with a social ID, so you have to change
                    your password credentials in this context
                  </P>
                  <Preview label="Sign up method" value={me.signupMethod} />
                </div>
              ) : (
                <div>
                  <Button ui="secondary">Request password reset</Button>
                </div>
              )}
            </Panel>
          </Grid.Col>
        </Grid.Row>
        <Modal
          open={avatarModalVisible}
          onClose={() => this.setState({ avatarModalVisible: false })}
        >
          <H5>Set my Avatar</H5>
          <ResourceForm
            resource="user"
            initialValues={me}
            onSubmit={({ values }) => this.update({ id: me.id, values })}
          >
            {() => (
              <>
                <Field name="avatar" />
                <Button ui="secondary" type="submit">
                  Save
                </Button>
              </>
            )}
          </ResourceForm>
          <br />
          <P>Sample avatars</P>
          <div>
            <GridList cellHeight={60} cols={12}>
              {AVATARS.map(tile => (
                <GridListTile key={tile.img}>
                  <img
                    src={tile.img}
                    alt=""
                    style={{
                      width: "48px",
                      height: "48px",
                      borderRadius: "50%",
                      marginRight: "0.5em"
                    }}
                  />
                </GridListTile>
              ))}
            </GridList>
          </div>
        </Modal>
      </SettingsLayout>
    );
  }
}
