import React from "react";
import { Link } from "components/app";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider
} from "@material-ui/core";
import { Icon } from "components/ui";
import AuthenticatedLayout from "./AuthenticatedLayout";

const SETTINGS_NAV = [
  {
    id: 1,
    icon: "user",
    label: "My Profile",
    blocker: "",
    to: "/profile"
  },
  {
    id: 4,
    icon: "company",
    label: "Account",
    blocker: "",
    to: "/account"
  },
  {
    id: 5,
    icon: "coins",
    label: "Billing",
    blocker: "settings.billing.view",
    to: "/billing"
  },
  {
    id: 6,
    icon: "team",
    label: "Users",
    blocker: "",
    to: "/users"
  },
  {
    id: 7,
    icon: "roles",
    label: "Roles",
    blocker: "",
    to: "/roles"
  },
  {
    id: 8,
    icon: "speed",
    label: "InfoCards",
    blocker: "",
    to: "/infocards"
  }
];

const SETTINGS_NAV_META = [
  {
    id: 10,
    icon: "entity",
    label: "Entities",
    blocker: "",
    to: "/entities"
  },
  {
    id: 11,
    icon: "attributes",
    label: "Attributes",
    blocker: "",
    to: "/attributes"
  },
  {
    id: 12,
    icon: "channel",
    blocker: "",
    label: "Channels",
    to: "/channels"
  },
  {
    id: 13,
    icon: "connector",
    label: "Connectors",
    blocker: "",
    to: "/connectors"
  },
  {
    id: 14,
    icon: "testtube",
    label: "Tests",
    blocker: "",
    to: "/tests"
  },
  {
    id: 15,
    icon: "sets",
    label: "Sets",
    to: "/sets"
  },
  {
    id: 16,
    icon: "columns",
    label: "Views",
    blocker: "",
    to: "/views"
  },
  {
    id: 17,
    icon: "filter",
    label: "Filters",
    blocker: "",
    to: "/filters"
  },
  {
    id: 18,
    icon: "tags",
    label: "Tags",
    blocker: "",
    to: "/tags"
  }
];

export default ({ title, children }) => (
  <AuthenticatedLayout title={title}>
    <div
      style={{
        display: "flex"
      }}
    >
      <div
        style={{
          width: "200px",
          overflow: "hidden",
          paddingRight: "1em"
        }}
      >
        <List component="nav" aria-label="Main mailbox folders">
          {SETTINGS_NAV.map(item => (
            <ListItem
              key={item.id}
              button
              component={Link}
              to={`/settings${item.to}`}
              style={{
                paddingLeft: "0px",
                paddingRight: "8px",
                paddingTop: "5px",
                paddingBottom: "5px"
              }}
            >
              <ListItemIcon>
                <Icon size="24" glyph={item.icon} />
              </ListItemIcon>
              <ListItemText
                style={{
                  paddingLeft: "0px"
                }}
                primary={item.label}
              />
            </ListItem>
          ))}

          <Divider />

          {SETTINGS_NAV_META.map(item => (
            <ListItem
              key={item.id}
              button
              component={Link}
              to={`/settings${item.to}`}
              style={{
                paddingLeft: "0px",
                paddingRight: "8px",
                paddingTop: "5px",
                paddingBottom: "5px"
              }}
            >
              <ListItemIcon>
                <Icon size="24" glyph={item.icon} />
              </ListItemIcon>
              <ListItemText
                style={{
                  paddingLeft: "0px"
                }}
                primary={item.label}
              />
            </ListItem>
          ))}

          <Divider />
          <br />

          <ListItem
            key="helpcenter"
            button
            component={Link}
            to="http://support.sheetly.io/en/"
            target="_blank"
            style={{
              paddingLeft: "0px",
              paddingRight: "8px",
              paddingTop: "5px",
              paddingBottom: "5px"
            }}
          >
            <ListItemText
              style={{
                paddingLeft: "0px"
              }}
              primary="Help Center"
            />
          </ListItem>
          <ListItem
            key="TCS"
            button
            component={Link}
            to="http://www.sheetly.io/terms-conditions.html"
            target="_blank"
            style={{
              paddingLeft: "0px",
              paddingRight: "8px",
              paddingTop: "5px",
              paddingBottom: "5px"
            }}
          >
            <ListItemText
              style={{
                paddingLeft: "0px"
              }}
              primary="Terms & Conditions"
            />
          </ListItem>
        </List>
      </div>
      <div
        style={{
          width: "-webkit-fill-available"
        }}
      >
        {children}
      </div>
    </div>
  </AuthenticatedLayout>
);
