import gql from "graphql-tag";

export default gql`
  query {
    activities {
      id
      type
      name
      user
      avatar
    }
  }
`;
