import React from "react";
import withData from "lib/withData";
import { Promo } from "models";
import { Grid, Toolbar, LinkButton } from "../../components/ui";
import { AuthenticatedLayout } from "../../components/app";
import PromoCard from "./PromoCard";

const getInitialProps = async ({ db }) => {
  const promos = await Promo(db).all();

  return {
    promos
  };
};

const Index = ({ loading, data }) => {
  return (
    <AuthenticatedLayout title="Whats New">
      <Toolbar>
        <LinkButton size="small" ui="secondary" to="/promos">
          Some button
        </LinkButton>
      </Toolbar>
      <Grid.Row>
        {loading && <p>Loading...</p>}

        {!loading &&
          data.promos.map(promo => (
            <Grid.Col s={4}>
              <PromoCard
                title={promo.name}
                description={promo.description}
                launchdate={new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit"
                }).format(promo.launchdate)}
                image={promo.image}
                path={promo.code}
                readurl={promo.readMoreUrl}
              />
            </Grid.Col>
          ))}
      </Grid.Row>
    </AuthenticatedLayout>
  );
};

export default withData(Index, getInitialProps);
