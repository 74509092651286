import React, { useState, useEffect } from "react";
import { Icon } from "@material-ui/core";
import WidgetsIcon from "@material-ui/icons/Widgets";
import { InspectorBar } from "components/app";
import { IMAGES_PATH } from "lib/constants";
import { Input } from ".";

const AssetsWidget = ({ onClose, rowNode: { data = {} }, onChange }) => {
  const [value, setValue] = useState({});

  useEffect(() => {
    setValue({ sys_categories: data.sys_categories });
  }, [data.sys_categories]);

  const handleChange = event => {
    const { name, value } = event.target;
    setValue({ [name]: value });
    onChange(event);
  };

  return (
    <>
      <strong>Assets</strong>
      <p>Here we put some content for assets</p>
      <p>Name: {data.name || ""}</p>
      <p>
        Primary image:{" "}
        {data.sys_primary_image && (
          <img
            src={`${IMAGES_PATH.thumbnail}/${data.sys_primary_image}`}
            alt={data.name || ""}
          />
        )}
      </p>
      <Input
        type="text"
        onChange={handleChange}
        name="sys_categories"
        label="Categories"
        variant="outlined"
        value={value.sys_categories || ""}
      />
    </>
  );
};

const ProductsWidget = ({ onClose, rowNode: { data = {} }, onChange }) => {
  const [value, setValue] = useState({});

  useEffect(() => {
    setValue({ sys_categories: data.sys_categories });
  }, [data.sys_categories]);

  const handleChange = event => {
    const { name, value } = event.target;
    setValue({ [name]: value });
    onChange(event);
  };

  return (
    <>
      <strong>Related Products</strong>
      <p>Some related products will be here</p>
      <p>Name: {data.name || ""}</p>
      <p>
        Primary image:{" "}
        {data.sys_primary_image && (
          <img
            src={`${IMAGES_PATH.thumbnail}/${data.sys_primary_image}`}
            alt={data.name || ""}
          />
        )}
      </p>
      <Input
        type="text"
        onChange={handleChange}
        name="sys_categories"
        label="Categories"
        variant="outlined"
        value={value.sys_categories || ""}
      />
    </>
  );
};

const TagsWidget = ({ onClose, rowNode: { data = {} }, onChange }) => {
  const [value, setValue] = useState({});

  useEffect(() => {
    setValue({ sys_categories: data.sys_categories });
  }, [data.sys_categories]);

  const handleChange = event => {
    const { name, value } = event.target;
    setValue({ [name]: value });
    onChange(event);
  };

  return (
    <>
      <strong>Tags</strong>
      <p>Content for tags</p>
      <p>Name: {data.name || ""}</p>
      <p>
        Primary image:{" "}
        {data.sys_primary_image && (
          <img
            src={`${IMAGES_PATH.thumbnail}/${data.sys_primary_image}`}
            alt={data.name || ""}
          />
        )}
      </p>
      <Input
        type="text"
        onChange={handleChange}
        name="sys_categories"
        label="Categories"
        variant="outlined"
        value={value.sys_categories || ""}
      />
    </>
  );
};

const CategoriesWidget = ({ onClose, rowNode: { data = {} }, onChange }) => {
  const [value, setValue] = useState({});

  useEffect(() => {
    setValue({ sys_categories: data.sys_categories });
  }, [data.sys_categories]);

  const handleChange = event => {
    const { name, value } = event.target;
    setValue({ [name]: value });
    onChange(event);
  };

  return (
    <>
      <strong>Categories</strong>
      <p>And here will be categories</p>
      <p>Name: {data.name || ""}</p>
      <p>
        Primary image:{" "}
        {data.sys_primary_image && (
          <img
            src={`${IMAGES_PATH.thumbnail}/${data.sys_primary_image}`}
            alt={data.name || ""}
          />
        )}
      </p>
      <Input
        type="text"
        onChange={handleChange}
        name="sys_categories"
        label="Categories"
        variant="outlined"
        value={value.sys_categories || ""}
      />
    </>
  );
};

const pickers = {
  assets2product: AssetsWidget,
  products2product4related: ProductsWidget,
  tags2product: TagsWidget,
  categories2product: CategoriesWidget
};

const SplashContent = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      background:
        "linear-gradient(335deg, rgba(108,0,223,1) 30%, rgba(0,168,255,1) 100%)",
      minHeight: "300px",
      color: "#fff"
    }}
  >
    <div>
      <WidgetsIcon style={{ fontSize: 100, marginBottom: 32 }} />
    </div>
    <h2>Sample Splash Screen</h2>
  </div>
);

export default function Widget({ open, picker, rowNode, onChange, onClose }) {
  const WidgetContent = pickers[picker];

  return (
    <InspectorBar open={open} size="small">
      {Object.keys(rowNode).length > 0 ? (
        WidgetContent && (
          <WidgetContent
            onClose={onClose}
            rowNode={rowNode}
            onChange={onChange}
          />
        )
      ) : (
        <SplashContent />
      )}
    </InspectorBar>
  );
}
